<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Navigation -->
<div
  class="nav-container"
  [ngClass]="{ resizing: isResizing }"
  #sideNavContainer>
  <fuse-vertical-navigation
    class="dark nav-content bg-transparent"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navItems.items"
    [opened]="!stateOfNav"
    (itemClicked)="receiveWorkspaceId($event)"
    [ngStyle]="{ minWidth: '0', maxWidth: '100%', width: navWidth + 'px' }">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="nav-header pt-[8px] pl-[30px] max-h-[3rem] mb-[1.5rem]">
        <!-- Light version -->
        <img
          class="logo-light"
          src="assets/images/logo/logo-text.svg"
          alt="Logo image" />
        <!-- Dark version -->
        <img
          class="logo-dark w-[126px] h-[35px]"
          [src]="navItems.items[0].img"
          alt="Logo image" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
  <div class="nav-resizer" (mousedown)="startResize($event)"></div>
</div>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 overflow-hidden">
  <!-- Header -->
  <div
    class="relative flex flex-row justify-between items-center w-full h-[3.5rem] px-4 md:px-6 z-49 bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <div class="px-4">
      <!-- home -->
      <div *ngIf="isHoamePage()">
        <div
          class="flex flex-row gap-x-4 text-gray-900 text-base font-semibold leading-7">
          <mat-icon class="flex items-center"
            ><img src="assets/icons/Home.svg"
          /></mat-icon>
        </div>
      </div>
      <!-- my tasks -->
      <div *ngIf="isMyTasksPage()">
        <div
          class="flex flex-row gap-x-2 text-gray-900 text-base font-semibold leading-7">
          <mat-icon class="flex items-center"
            ><img class="h-5 w-5" src="assets/icons/mytasks.svg"
          /></mat-icon>
          <span
            class="text-[#111827] text-lg font-sans font-semibold leading-7"
            >{{ 'MY_TASKS_TAB' | translate }}</span
          >
        </div>
      </div>
      <!-- project -->
      <div
        *ngIf="isProjectPage()"
        class="flex flex-row justify-center items-center gap-x-4">
        <img
          class="w-[33px] h-[33px] flex-none rounded-full bg-gray-50"
          src="assets\21.jpg"
          alt="projectImage" />
        <h1
          *ngIf="projectName"
          class="font-sans text-lg text-[#111827] font-semibold text-gray-900 main-header">
          {{ projectName }}
        </h1>
        <button (click)="openProjectSettings()">
          <mat-icon class="flex items-center"
            ><img src="assets/icons/settingsIcon.svg"
          /></mat-icon>
        </button>
      </div>
      <!-- my tasks -->
    </div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <div
        *ngIf="isProjectPage()"
        class="flex flex-row gap-x-4 items-center justify-center pr-6 border-r">
        <button
          (click)="openProjectInvites()"
          class="btn-secondary-icon-hover h-[32px] text-[14px] font-semibold">
          <mat-icon
            class="text-black text-sm"
            [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          {{ 'INVITE' | translate }}
        </button>
        <!-- invite button  -->
      </div>
      <div>
        <!-- <shortcuts></shortcuts> -->
        <notifications></notifications>
        <user [role]="role" (workspaceEmiter)="receiveWorkspaceId($event)">
        </user>
      </div>
    </div>
  </div>
  <missing-verification-banner *ngIf="!isAccountVerified">
  </missing-verification-banner>

  <div
    *ngIf="isProjectPage()"
    class="border-t border-gray-300 header-bar h-[3.5rem]">
    <div class="header-buttons">
      <!-- <button
        [routerLink]="[
          'projects/project',
          currentProjectId,
          'project-overview'
        ]"
        routerLinkActive
        #rla="routerLinkActive"
        class="header-button"
        [class.active]="rla.isActive">
        <mat-icon>
          <img
            class="icon"
            src="assets/icons/ProjectOverview.svg"
            *ngIf="rla.isActive; else inactiveIcon1" />
          <ng-template #inactiveIcon1>
            <img class="icon" src="assets/icons/OverViewScouleur.svg" />
          </ng-template>
        </mat-icon>
        Aperçu
      </button> -->

      <button
        [routerLink]="['projects/project', currentProjectId, 'workshops']"
        routerLinkActive
        #rla3="routerLinkActive"
        class="header-button"
        [class.active]="rla3.isActive">
        <mat-icon>
          <img
            class="icon"
            src="assets/icons/workshop.svg"
            *ngIf="!rla3.isActive; else inactiveIcon3" />
          <ng-template #inactiveIcon3>
            <img class="icon" src="assets/icons/workshopsCouleur.svg" />
          </ng-template>
        </mat-icon>
        {{ 'WORKSHOPS_TAB' | translate }}
      </button>

      <button
        [routerLink]="['projects/project', currentProjectId, 'boards']"
        routerLinkActive
        #rla2="routerLinkActive"
        class="header-button"
        [class.active]="rla2.isActive">
        <mat-icon>
          <img
            class="icon"
            src="assets/icons/Boards.svg"
            *ngIf="!rla2.isActive; else inactiveIcon2" />
          <ng-template #inactiveIcon2>
            <img class="icon" src="assets/icons/boardsIcon.svg" />
          </ng-template>
        </mat-icon>
        {{ 'BOARDS_TAB' | translate }}
      </button>

      <button
        [routerLink]="['projects/project', currentProjectId, 'documents']"
        routerLinkActive
        #filesRoute="routerLinkActive"
        class="header-button"
        [class.active]="filesRoute.isActive">
        <mat-icon>
          <img
            class="icon"
            src="assets/icons/Files.svg"
            *ngIf="!filesRoute.isActive; else inactiveIcon4" />
          <ng-template #inactiveIcon4>
            <img class="icon" src="assets/icons/FilesIconGreen.svg" />
          </ng-template>
        </mat-icon>
        {{ 'FILES_TAB' | translate }}
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet
      *ngIf="true"
      [currentProjectId]="currentProjectId"></router-outlet>
  </div>
</div>
<!-- Quick chat -->
<!-- <quick-chat #quickChat=""></quick-chat> -->
