<!-- Item wrapper -->
<div
  class="fuse-vertical-navigation-item-wrapper"
  [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item.classes?.wrapper">
  <!-- Item with an internal link -->
  <ng-container
    class="border-2 border-black"
    *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
    <div
      *ngIf="item.link !== 'log' && item.link !== 'members&teams'"
      [routerLink]="[item.link]"
      class="flex flex-row justify-between items-center rounded-md bg-white hover:bg-gray-100 cursor-pointer min-h-[40px] max-h-[40px]"
      [ngClass]="{
        'border-t border-gray-200': item.title === 'Projects',
        'bg-gray-100': selectedItemId === item.id
      }">
      <a
        class="fuse-vertical-navigation-item pl-[10px]"
        [ngClass]="{
          'fuse-vertical-navigation-item-active-forced': item.active
        }"
        [fragment]="item.fragment ?? null"
        [preserveFragment]="item.preserveFragment ?? false"
        [queryParams]="item.queryParams ?? null"
        [queryParamsHandling]="item.queryParamsHandling ?? null"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="isActiveMatchOptions"
        [matTooltip]="item.tooltip || ''">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
      </a>
      <ng-container *ngIf="item.button">
        <button
          (click)="openAddprojectDialog()"
          class="flex-shrink-0 ml-auto mr-[5px] rounded transition-colors duration-200 hover:bg-[#E5E7EB] max-h-[25px] max-w-[25px]">
          <mat-icon [svgIcon]="item.button.icon"></mat-icon>
        </button>
      </ng-container>
    </div>
    <div
      *ngIf="item.link === 'log'"
      [routerLink]="['/admin', workspaceId, 'log']"
      class="flex flex-row justify-between items-center rounded-md bg-white hover:bg-gray-100 cursor-pointer"
      [ngClass]="{
        'border-t border-gray-200': item.title === 'Projects',
        'bg-gray-100 text-[#39CB9E]': selectedItemId === item.id
      }">
      <a
        class="fuse-vertical-navigation-item"
        [ngClass]="{
          'fuse-vertical-navigation-item-active-forced': item.active
        }"
        [fragment]="item.fragment ?? null"
        [preserveFragment]="item.preserveFragment ?? false"
        [queryParams]="item.queryParams ?? null"
        [queryParamsHandling]="item.queryParamsHandling ?? null"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="isActiveMatchOptions"
        [matTooltip]="item.tooltip || ''">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
      </a>
    </div>
    <div
      *ngIf="item.link === 'members&teams'"
      [routerLink]="['/admin', workspaceId, 'members-list']"
      class="flex flex-row justify-between items-center rounded-md bg-white hover:bg-gray-100 cursor-pointer"
      [ngClass]="{
        'border-t border-gray-200': item.title === 'Projects',
        'bg-gray-100': selectedItemId === item.id
      }">
      <a
        class="fuse-vertical-navigation-item"
        [ngClass]="{
          'fuse-vertical-navigation-item-active-forced': item.active
        }"
        [fragment]="item.fragment ?? null"
        [preserveFragment]="item.preserveFragment ?? false"
        [queryParams]="item.queryParams ?? null"
        [queryParamsHandling]="item.queryParamsHandling ?? null"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="isActiveMatchOptions"
        [matTooltip]="item.tooltip || ''">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
      </a>
    </div>
  </ng-container>

  <!-- Item with a function -->
  <ng-container *ngIf="!item.link && item.function && !item.disabled">
    <div
      class="fuse-vertical-navigation-item"
      [ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
      [matTooltip]="item.tooltip || ''"
      (click)="item.function(item)">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- Item with an internal link and function -->
  <ng-container
    *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
    <a
      class="fuse-vertical-navigation-item"
      [ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
      [routerLink]="[item.link]"
      [fragment]="item.fragment ?? null"
      [preserveFragment]="item.preserveFragment ?? false"
      [queryParams]="item.queryParams ?? null"
      [queryParamsHandling]="item.queryParamsHandling ?? null"
      [routerLinkActive]="'fuse-vertical-navigation-item-active'"
      [routerLinkActiveOptions]="isActiveMatchOptions"
      [matTooltip]="item.tooltip || ''"
      (click)="item.function(item)">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with an external link and function -->
  <ng-container
    *ngIf="item.link && item.externalLink && item.function && !item.disabled">
    <a
      class="fuse-vertical-navigation-item"
      [href]="item.link"
      [target]="item.target || '_self'"
      [matTooltip]="item.tooltip || ''"
      (click)="item.function(item)">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>

  <!-- Item with a no link and no function -->
  <ng-container *ngIf="!item.link && !item.function && !item.disabled">
    <div
      class="fuse-vertical-navigation-item"
      [ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
      [matTooltip]="item.tooltip || ''">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- Item is disabled -->
  <ng-container *ngIf="item.disabled">
    <div
      class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled"
      [matTooltip]="item.tooltip || ''">
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<!-- Item template -->
<ng-template #itemTemplate>
  <!-- Icon -->
  <ng-container *ngIf="item.icon">
    <mat-icon
      class="min-w-[32px] min-h-[32px] px-[2.25px] pt-[2.72px] pb-[3px] pl-0 justify-start items-start inline-flex"
      [ngClass]="item.classes?.icon"
      [svgIcon]="
        item.id === 'My Tasks'
          ? 'heroicons_solid:clipboard-myWork'
          : item.id === 'Projects'
          ? 'heroicons_solid:folder-projects'
          : item.icon
      "
      [style.color]="
        item.id === selectedItemId && !item.isAdmin ? '#39CB9E' : '#94a3b8'
      "></mat-icon>
  </ng-container>

  <div class="flex flex-row justify-between items-center w-full">
    <!-- Title & Subtitle -->
    <div class="fuse-vertical-navigation-item-title-wrapper">
      <!-- <div *ngIf="item.title==='Projects'" class="w-[194px] h-[0px] border border-gray-200"></div> -->
      <div
        class="text-[#111827] text-[14px] font-medium leading-tight ml-[8px]"
        *ngIf="!item.isAdmin">
        <span
          [ngClass]="item.classes?.title"
          [style.color]="
            item.id === selectedItemId && !item.isAdmin ? '#1F2937' : 'inherit'
          "
          (click)="setSelectedItem(item.id, item)">
          {{ item.title | translate }}
        </span>
      </div>
      <div
        class="text-slate-800 text-[14px] font-medium leading-tight"
        *ngIf="item.isAdmin">
        <span
          [ngClass]="item.classes?.title"
          [style.color]="'#111827'"
          [ngClass]="{
            'text-[#39CB9E]': item.id === selectedItemId,
            'text-[#111827]': item.id !== selectedItemId
          }"
          (click)="setSelectedItem(item.id)">
          {{ item.title | translate }}
        </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="fuse-vertical-navigation-item-subtitle">
          <span [ngClass]="item.classes?.subtitle">
            {{ item.subtitle }}
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="fuse-vertical-navigation-item-badge">
        <div
          class="fuse-vertical-navigation-item-badge-content"
          [ngClass]="item.badge.classes">
          {{ item.badge.title }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
