import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { colors } from '@excelway/constants/color';
import { icons } from '@excelway/constants/icons';
import { EventTypes, SentEvent } from '@excelway/models/socket-io/event';
import { SocketService } from '@excelway/services/socket-io.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CommonService } from 'app/common-dialogs/common.services';
import { DocumentsService } from 'app/modules/documents/documents.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProjectsStore } from '../../projects.store';
import { Project } from '../models/project';
import { ProjectSettingsService } from '../project-settings.service';

@Component({
  selector: 'object-details',
  templateUrl: './object-details.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class ObjectDetailsComponent implements OnInit {
  project: Project;

  objectSettingsForm = new FormGroup({
    name: new FormControl<string | undefined>(''),
    description: new FormControl<string | undefined>(''),
    startDate: new FormControl<Date | undefined>(new Date()),
    endDate: new FormControl(),
    isPublic: new FormControl<boolean>(false),
  });

  projectAppearanceForm = new FormGroup({
    color: new FormControl<string | undefined>(''),
    icon: new FormControl<string | undefined>(''),
    logo: new FormControl<string | undefined>(''),
    selectedOption: new FormControl<string>('colorIcon'),
  });

  colors = colors;
  icons = icons;
  logo$: Observable<string | undefined>;
  private logoSubject = new BehaviorSubject<string | undefined>(undefined);
  selectedOption: 'logo' | 'colorIcon' = 'colorIcon';

  constructor(
    public _matDialogRef: MatDialogRef<ObjectDetailsComponent>,
    private _projectSettingService: ProjectSettingsService,
    private _fileManagerService: DocumentsService,
    private readonly _projectsStore: ProjectsStore,
    private _fuseConfirmationService: FuseConfirmationService,
    private _router: Router,
    private datePipe: DatePipe,
    private socketService: SocketService,
    private _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {
    this.logo$ = this.logoSubject.asObservable();
  }

  ngOnInit(): void {
    this.getProjectDetails();

    this.projectAppearanceForm.controls.selectedOption.valueChanges.subscribe(
      value => {
        if (value === 'logo') {
          this.projectAppearanceForm.controls.color.disable();
          this.projectAppearanceForm.controls.icon.disable();
        } else {
          this.projectAppearanceForm.controls.color.enable();
          this.projectAppearanceForm.controls.icon.enable();
        }
      }
    );
  }

  getProjectDetails(): void {
    const query = {
      roleType: 'Project',
      id: this.data.id,
      fields: [
        'id',
        'name',
        'description',
        'startDate',
        'endDate',
        'logo',
        'icon',
        'color',
      ],
    };
    this._projectSettingService
      .getProjectsAndUsers(query)
      .subscribe((response: any) => {
        this.project = response;

        this.objectSettingsForm.controls['name'].setValue(this.project?.name);
        this.objectSettingsForm.controls['description'].setValue(
          this.project?.description
        );
        this.objectSettingsForm.controls['startDate'].setValue(
          this.project?.startDate
        );
        this.objectSettingsForm.controls['endDate'].setValue(
          this.project?.endDate
        );
        this.projectAppearanceForm.controls['logo'].setValue(
          this.project?.logo
        );
        this.projectAppearanceForm.controls['color'].setValue(
          this.project?.color
        );
        this.projectAppearanceForm.controls['icon'].setValue(
          this.project?.icon
        );

        if (this.project?.logo) {
          this.logoSubject.next(this.project.logo);
          this.projectAppearanceForm.controls.selectedOption.setValue('logo');
        } else {
          this.projectAppearanceForm.controls.selectedOption.setValue(
            'colorIcon'
          );
        }
      });
  }

  selectProperty(type: 'color' | 'icon', value: string): void {
    this.projectAppearanceForm.controls[type].setValue(value);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      // Validate file type
      if (!file.type.startsWith('image/')) {
        return;
      }
      const additionalData = {
        createdAt: new Date().toISOString(),
        size: file.size.toString(),
        documentType: file.type,
      };

      this.uploadProjectBackground(file, additionalData).subscribe();
    }
  }

  uploadProjectBackground(file: File, additionalData: any): Observable<any> {
    const projectId = this.project?.id;
    return this._fileManagerService
      .createObjectBackground(projectId, 'Project', file, additionalData)
      .pipe(
        tap(() => {
          this.getProjectDetails();
        })
      );
  }

  saveChanges(): void {
    const objectData = {
      startDate: this.datePipe.transform(
        this.objectSettingsForm.value.startDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        this.objectSettingsForm.value.endDate,
        'yyyy-MM-dd'
      ),
      name: this.objectSettingsForm.value.name,
      description: this.objectSettingsForm.value.description,
      color: this.projectAppearanceForm.value.color,
      icon: this.projectAppearanceForm.value.icon,
    };

    this._projectSettingService
      .updateObjectDetails('project', this.data.id, objectData)
      .subscribe(() => {
        const event: SentEvent = {
          roomId: 'project-room',
          eventType: EventTypes.PROJECT_UPDATED,
          payload: { id: this.data.id, ...objectData },
        };
        this.socketService.publishEvent(event);
        // Trigger the initializeComponent method
        this._commonService.triggerInitializeComponent();
      });
    this._matDialogRef.close(this.objectSettingsForm.value.name);
  }

  deleteProject(): void {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Remove project',
      message:
        'Are you sure you want to delete this project? This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
      },
    });
    confirmation.afterClosed().subscribe(result => {
      if (result === 'confirmed') {
        this._projectsStore.deleteProject(this.data.id);
        this._matDialogRef.close();
        this._router.navigate(['/projects']);
      }
    });
  }
}
