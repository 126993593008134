import { Injectable } from '@angular/core';
import { TranslationService } from '@excelway/services/translation.service';

import { NavigationService } from 'app/core/navigation/navigation.service';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver {
  interfaceId = 'cm1p5fzvz001ug0btypumbusa';
  /**
   * Constructor
   */
  constructor(
    private _messagesService: MessagesService,
    private _navigationService: NavigationService,
    private _quickChatService: QuickChatService,
    private _shortcutsService: ShortcutsService,
    private _translationService: TranslationService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(): Observable<any> {
    return forkJoin([
      this._translationService.loadComponentTranslations(this.interfaceId),
      this._navigationService.get(),
      this._messagesService.getAll(),
      this._quickChatService.getChats(),
      this._shortcutsService.getAll(),
    ]);
  }
}
