import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseAlertModule } from '@fuse/components/alert';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { TagychipsModule } from '@excelway/components/tagychips/tagychips.module';
import { BoardsCrudAuthorizationDirective } from '@excelway/directives/authorization/boards/crud-directive';
import { BoardsMembersAuthorizationDirective } from '@excelway/directives/authorization/boards/members-management.directive';
import { provideComponentStore } from '@ngrx/component-store';
import { SharedModule } from 'app/shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BoardStore } from '../board/board.store';
import { AccessInvitesComponent } from './access-and-invites/access-and-invites.component';
import { BoardSettingsComponent } from './board-settings.component';
import { ChooseNotificationsComponent } from './choose-notifications/choose-notifications.component';
import { CustomizeCardsFieldsComponent } from './customize-cards-fields/customize-cards-fields.component';
import { DataExportComponent } from './data-export/data-export.component';
import { ObjectDetailsComponent } from './object-details/object-details.component';
import { SettingsTeamComponent } from './team/team.component';
import { TemplateAvailabilityComponent } from './template-panels/availability/availability.component';
import { TemplateTeamComponent } from './template-panels/team/team.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatTreeModule,
    MatCheckboxModule,
    TagychipsModule,
    CommonModule,
    FuseAlertModule,
    SharedModule,
    ToastrModule.forRoot(),
    BoardsMembersAuthorizationDirective,
    BoardsCrudAuthorizationDirective,
    TranslateModule,
  ],
  providers: [
    provideComponentStore(BoardStore),
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_LUXON_DATE_FORMATS,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  declarations: [
    BoardSettingsComponent,
    ObjectDetailsComponent,
    AccessInvitesComponent,
    DataExportComponent,
    SettingsTeamComponent,
    ChooseNotificationsComponent,
    CustomizeCardsFieldsComponent,
    TemplateAvailabilityComponent,
    TemplateTeamComponent,
  ],
  exports: [
    BoardSettingsComponent,
    ObjectDetailsComponent,
    AccessInvitesComponent,
    DataExportComponent,
    SettingsTeamComponent,
  ],
})
export class BoardSettingsModule {}
