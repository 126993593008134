<div
  class="w-full max-w-8xl"
  [ngClass]="{
    'opacity-50 pointer-events-none': role === 'read',
    'opacity-100 pointer-events-auto': role !== 'read'
  }">
  <!-- Form -->
  <form>
    <!-- Board Settings -->
    <ng-container *ngIf="!data.value?.isTemplate">
      <form [formGroup]="objectSettingsForm">
        <!-- Name -->
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label class="text-black settings-section-title">
              {{ 'BOARD_NAME_TEXT' | translate }}</mat-label
            >
            <input
              formControlName="name"
              matInput
              required
              [placeholder]="'BOARD_NAME_PLACEHOLDER' | translate" />
          </mat-form-field>
        </div>
        <!-- Description -->
        <div class="flex">
          <mat-form-field class="flex-auto h-[10rem]">
            <mat-label class="text-black settings-section-title">{{
              'BOARD_DESCRIPTION_TEXT' | translate
            }}</mat-label>
            <textarea
              matInput
              [formControlName]="'description'"
              [placeholder]="''"
              [rows]="3"
              class="max-h-[100%] h-[100%] py-0 my-[1.5rem]">
            </textarea>
          </mat-form-field>
        </div>
        <!-- Currency -->
        <!-- <div class="flex flex-col gt-xs:flex-row">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-label class="text-black">Board currency</mat-label>
            <mat-select formControlName="currency">
              <mat-option
               
                *ngFor="let currency of currencies"
                [value]="currency">
                {{ currency }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- Duration Units -->
        <!-- <div class="flex flex-col gt-xs:flex-row">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-label class="text-black">Board Duration Units</mat-label>
            <mat-select formControlName="durationUnit">
              <mat-option *ngFor="let unit of durationUnits" [value]="unit">
                {{ unit }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- Date -->
        <div class="sm:col-span-4 w-full flex flex-row gap-2 mt-2">
          <mat-form-field
            color="accent"
            class="block text-sm w-full font-semibold leading-6 text-gray-900">
            <mat-label class="text-black settings-section-title">{{
              'BOARD_START_DATE_TEXT' | translate
            }}</mat-label>
            <input
              matInput
              formControlName="startDate"
              [matDatepicker]="picker1" />
            <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 color="primary"></mat-datepicker>
          </mat-form-field>

          <mat-form-field
            color="accent"
            class="block text-sm w-full font-semibold leading-6 text-gray-900">
            <mat-label class="text-black settings-section-title">{{
              'BOARD_END_DATE_TEXT' | translate
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="endDate" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
          </mat-form-field>
        </div>
        <button
          class="w-1/5 -mx-0.5 mb-1.5 btn-secondary-hover settings-section-title"
          (click)="saveAsTemplate()"
          type="button">
          {{ 'SAVE_AS_TEMPLATE_BUTTON' | translate }}
        </button>
      </form>

      <!-- Divider -->
      <div class="mt- mb-3 border-t"></div>
      <!-- Appearance -->
      <div class="flex flex-col gap-4 mb-4 ml-1.5">
        <label
          class="text-base font-medium text-black settings-section-title"
          >{{ 'BOARD_APPARENCE_TEXT' | translate }}</label
        >
        <div
          class="w-72 h-28 bg-white rounded-md justify-start items-start inline-flex ml-4">
          <div class="grid grid-cols-8 mt-3 p-3 gap-3">
            <div *ngFor="let color of colors">
              <button
                (click)="selectColor(color)"
                [style.background-color]="color"
                [ngClass]="{
                  'border-2 border-gray-600':
                    objectSettingsForm.controls['color'].value === color
                }"
                class="rounded h-6 w-6"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt- mb-3 border-t"></div>
      <div class="flex flex-col" *ngIf="role === 'admin'">
        <div class="text-base font-medium text-black settings-section-title">
          {{ 'DELETE_BOARD_TEXT' | translate }}
        </div>
        <div class="mt-2 comment-text">
          {{ 'DELETION_DESCRIPTION' | translate }}
        </div>
        <button
          class="mt-4 px-4 max-w-[13rem] btn-danger-hover"
          (click)="openDeleteConfirmation()"
          type="button">
          {{ 'DELETE_BOARD_BUTTON' | translate }}
        </button>
      </div>

      <!-- Actions -->
      <div class="flex items-center justify-end">
        <button
          (click)="this._matDialogRef.close()"
          mat-dialog-close
          class="btn-tertiary my-2 max-h-[36px] flex items-center border border-gray-200"
          type="button">
          {{ 'CANCEL_BUTTON' | translate }}
        </button>
        <button
          (click)="saveChanges()"
          class="btn-primary-hover my-2 max-h-[36px] flex items-center"
          type="button">
          {{ 'SAVE_BUTTON' | translate }}
        </button>
      </div>
    </ng-container>

    <!-- Template Settings -->
    <ng-container *ngIf="data.value?.isTemplate">
      <form [formGroup]="templateSettingsForm">
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label class="text-black">Template name</mat-label>
            <input
              formControlName="name"
              #nameInput
              (focusout)="updateTemplateField('name', nameInput.value)"
              (keydown.enter)="updateTemplateField('name', nameInput.value)"
              matInput
              required />
          </mat-form-field>
        </div>
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label class="text-black">Short description</mat-label>
            <input
              formControlName="description"
              #descriptionInput
              (focusout)="
                updateTemplateField('description', descriptionInput.value)
              "
              (keydown.enter)="
                updateTemplateField('description', descriptionInput.value)
              "
              matInput
              required />
          </mat-form-field>
        </div>
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label class="text-black">Long description</mat-label>
            <textarea
              formControlName="longDescription"
              #longDescriptionInput
              (focusout)="
                updateTemplateField(
                  'longDescription',
                  longDescriptionInput.value
                )
              "
              (keydown.enter)="
                updateTemplateField(
                  'longDescription',
                  longDescriptionInput.value
                )
              "
              matInput
              [rows]="3">
            </textarea>
          </mat-form-field>
        </div>
        <div class="flex flex-col gt-xs:flex-row">
          <mat-form-field class="flex-auto gt-xs:pr-3">
            <mat-label class="text-black">Category</mat-label>
            <mat-select
              formControlName="category"
              (selectionChange)="updateTemplateField('category', $event.value)"
              required>
              <mat-option value="sales_customer"
                >Sales and customer relations</mat-option
              >
              <mat-option value="project_management"
                >Project management</mat-option
              >
              <mat-option value="human_resources">Human resources</mat-option>
              <mat-option value="information_tech">IT</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-col">
          <div class="text-black font-semibold">Template thumbnail</div>
          <div class="mt-1">
            Add a thumbnail image to make the template easily recognizable
          </div>
          <div class="flex flex-row mt-2">
            <mat-icon style="font-size: 30px; width: 30px; height: 30px"
              >wallpaper</mat-icon
            >
            <button
              type="file"
              #thumbnail
              class="ml-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-2 border border-gray-400 rounded shadow">
              Add
            </button>
            <input dir="auto" hidden type="file" accept="image/*" #fileInput />
          </div>
        </div>
      </form>
    </ng-container>
  </form>
</div>
