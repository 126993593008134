<div>
    <form class="flex flex-col" [formGroup]="memberForm" *ngIf="memberForm">
      <p class="mt-6 text-black mb-2 text-lg font-semibold">
        {{ 'TEMPLATE_BOARD_SETTINGS_ACCESS_HEADER' | translate}}
      </p>
    <p class="my-2 font-medium text-[#4B5563]">
      {{ 'TEMPLATE_BOARD_SETTINGS_ACCESS_TEXT' | translate}}
    </p>
     <div class="w-full max-w-8x">
        <!-- Add team member -->
        <div class="w-full">
         <div class="flex flex-col">
           <div class="flex flex-row gap-4" *ngIf="templateUsers && workspaceUsers">
            <app-tagychips
               class="mt-3"
               type="email"
               [formControlName]="'memberEmail'"   
               class="w-full"
               [objects]="workspaceUsers"
               [config]="coEditorsInputConfig"
               (addTagychip)="onAddMember($event)"
               >
            </app-tagychips>

             <div class="flex flex-col gap-1">

               <button

                 type="button"

                 (click)="addMember(this.data.id, 'Board', this.roleTypeParentId)"

                 class="px-4 py-3 bg-[#36CC9F] text-white rounded-md">

                 {{ 'TEMPLATE_BOARD_SETTINGS_ACCESS_BUTTON' | translate}}

               </button>

             </div>

           </div>

           <div class="flex flex-row">

             <div *ngIf="memberForm.get('memberEmail')?.dirty && memberForm.get('memberEmail')?.hasError('pattern')">

                <div *ngIf="memberForm.get('memberEmail')?.hasError('pattern')" class="text-red-400 font-medium">

                Invalid email format.

                </div>

              </div>

          </div>

         </div>

        </div>
        <div class="flex flex-col mt-7 divide-y border-t" *ngIf="templateUsers">

          <div class="container">

            <ng-container *ngFor="let member of templateUsers">

              <div class="flex flex-col sm:flex-row sm:items-center py-3">

                <div class="flex items-center">

                  <div

                    class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">

                    <ng-container *ngIf="member.avatar">

                      <img

                        class="object-cover w-full h-full"

                        [src]="member.avatar"

                        alt="Contact avatar" />

                    </ng-container>

                    <ng-container *ngIf="!member.avatar">

                      <div

                        class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">

                        {{ member.email?.charAt(0) }}

                      </div>

                    </ng-container>

                  </div>

                  <div class="ml-4">

                    <div class="font-medium">{{ member.name }}</div>

                    <div class="text-secondary">{{ member.email }}</div>

                  </div>

                </div>

                <div class="flex items-center mt-4 sm:mt-0 sm:ml-auto">

                  <div class="order-1 sm:order-2 sm:ml-3">

                    <button

                      mat-icon-button

                      (click)="removeMember(member.email, member.id)"

                      >

                      <mat-icon

                        class="text-hint"

                        [svgIcon]="'heroicons_outline:trash'"></mat-icon>

                    </button>

                  </div>

                </div>

              </div>

            </ng-container>

          </div>

       </div>

     </div>

    </form>

</div>