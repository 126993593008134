import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUser } from '@excelway/types/auth-user.types';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  Field,
  InterfaceStore,
} from 'app/modules/user-interface/user-interface.store';
import { AuthStoreSelectors } from 'app/store/auth';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  Subscription,
  take,
  tap,
} from 'rxjs';

interface ComponentTranslationCache {
  [interfaceId: string]: {
    fields: Field[];
    timestamp: number;
    language: string;
  };
}

const languageMap: { [key: string]: string } = {
  en: 'English',
  fr: 'French',
  ar: 'Arabic',
};

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private readonly CACHE_DURATION = 5 * 60 * 1000;
  private readonly translationCache: ComponentTranslationCache = {};
  private readonly loadingTranslations = new Map<string, Observable<Field[]>>();
  private readonly user$: Observable<AuthUser | null>;
  private readonly currentUser = new BehaviorSubject<AuthUser | null>(null);
  activeTranslations = new BehaviorSubject<{
    [key: string]: boolean;
  }>({});

  constructor(
    private readonly _store: Store,
    private _interfaceStore: InterfaceStore,
    private _httpClient: HttpClient,
    private _translate: TranslateService
  ) {
    this.user$ = this._store.select(AuthStoreSelectors.selectLoggedUser).pipe(
      tap(user => this.currentUser.next(user)),
      shareReplay(1)
    );

    // Initialize default language
    this._translate.setDefaultLang('en');
    this.user$.subscribe();
  }

  loadComponentTranslations(
    interfaceId: string,
    userLanguage?: string,
    forceReload: boolean = false
  ): Observable<Field[]> {
    const preferredLanguageCode =
      this.currentUser.value?.language ?? userLanguage ?? 'en';
    const userCurrentLanguage = languageMap[preferredLanguageCode] || 'English';

    const currentTranslations = this.activeTranslations.value;

    // Ensure the interface state is reset only if necessary (forceReload or not cached)
    if (forceReload || !currentTranslations[interfaceId]) {
      this.activeTranslations.next({
        ...currentTranslations,
        [interfaceId]: false,
      });
    }

    // Check if cached translations exist and are still valid
    const cached = this.translationCache[interfaceId];
    if (
      !forceReload &&
      cached &&
      this.activeTranslations.value[interfaceId] &&
      cached.language === userCurrentLanguage &&
      Date.now() - cached.timestamp < this.CACHE_DURATION
    ) {
      this.setTranslations(cached.fields, userCurrentLanguage);
      this.activeTranslations.next({
        ...currentTranslations,
        [interfaceId]: true,
      });
      return of(cached.fields);
    }

    // Avoid duplicate loading by checking for an existing loading state
    if (this.loadingTranslations.has(interfaceId)) {
      return this.loadingTranslations.get(interfaceId)!;
    }

    // Trigger the fetch process if it's not in progress
    this._interfaceStore.getInterfaceFields({ interfaceId });

    const translationLoader$ = this._interfaceStore.fields$.pipe(
      filter(fields => fields.length > 0),
      take(1),
      tap(fields => {
        this.translationCache[interfaceId] = {
          fields,
          timestamp: Date.now(),
          language: userCurrentLanguage,
        };

        this.setTranslations(fields, userCurrentLanguage);

        const current = this.activeTranslations.value;
        this.activeTranslations.next({ ...current, [interfaceId]: true });
      }),
      finalize(() => {
        this.loadingTranslations.delete(interfaceId);
      }),
      shareReplay(1)
    );

    // Cache the loading observable to prevent multiple requests
    this.loadingTranslations.set(interfaceId, translationLoader$);

    return translationLoader$;
  }

  private setTranslations(fields: Field[], language: string): void {
    const formattedTranslations = this.formatTranslations(fields, language);

    // Merge with existing translations instead of replacing
    const currentTranslations = this._translate.instant(
      Object.keys(formattedTranslations)
    );
    const mergedTranslations = {
      ...currentTranslations,
      ...formattedTranslations,
    };

    this._translate.setTranslation(language, mergedTranslations, true);
    this._translate.use(language);
  }

  private formatTranslations(
    fields: Field[],
    language: string
  ): { [key: string]: string } {
    const formatted: { [key: string]: string } = {};
    fields.forEach(field => {
      if (field.value?.languages?.[language]) {
        formatted[field.name] = field.value.languages[language];
      }
    });
    return formatted;
  }

  switchLanguage(language: string, userId: string): Observable<any> {
    const apiUrl = `${environment.backendUrl}/v1/update-language/${userId}`;

    return this._httpClient
      .patch(apiUrl, { language }, { withCredentials: true })
      .pipe(
        map(() => {
          this.updateUserLanguage(language);
        }),
        catchError(error => {
          console.error('Error updating language', error);
          return of(null);
        })
      );
  }

  private updateUserLanguage(language: string): void {
    // Access the current user value and update its language
    const currentUser = this.currentUser.value;
    if (currentUser) {
      // Create a new object to update the language property immutably
      this.currentUser.next({ ...currentUser, language });
      this.loadTranslationsSubscriptionVersion('interface-id', language);
      this.loadComponentTranslations('interface-id', language);
    }
  }

  fields$ = this._interfaceStore.fields$;
  fields: any[] = [];

  //Old service that works for other components than app
  //TODO make adjust later to work with one service
  loadTranslationsSubscriptionVersion(
    interfaceId: string,
    language?: string
  ): Subscription {
    const preferredLanguageCode =
      this.currentUser.value?.language ?? (language || 'en');
    const userLanguage = languageMap[preferredLanguageCode] || 'English';

    // Trigger the fetch of interface fields
    this._interfaceStore.getInterfaceFields({ interfaceId });
    // Subscribe to the fields$ observable
    return this.fields$.subscribe(fields => {
      this.fields = fields;
      const formattedTranslations = this.formatTranslations(
        this.fields,
        userLanguage
      );
      this._translate.setTranslation(userLanguage, formattedTranslations);
      this._translate.use(userLanguage);
    });
  }
}
