<div
  class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden bg-white">
  <mat-drawer-container class="flex-auto sm:h-full bg-white">
    <!-- Drawer -->
    <mat-drawer
      class="sm:w-96 dark:bg-gray-900"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #drawer>
      <!-- Header -->
      <div class="flex items-center justify-between m-8 mr-6 sm:my-10">
        <!-- Title -->
        <div
          class="text-xl w-full h-5 font-semibold tracking-tight leading-none settings-header-text">
          <span *ngIf="!isTemplate">
            {{ 'BOARD_SETTINGS_DIALOG_NAME' | translate }}
          </span>
          <span *ngIf="isTemplate"> {{ 'TEMPLATE_BOARD_SETTINGS_HEADER' | translate}} </span>
        </div>
        <!-- Close button
        <div class="">
          <button mat-icon-button (click)="drawer.close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
          </button>
        </div> -->
      </div>
      <!-- Panel links -->
      <div class="flex flex-col divide-y border-t border-b">
        <ng-container
          *ngFor="
            let panel of isTemplate ? templatePanels : boardPanels;
            trackBy: trackByFn
          ">
          <div
            class="flex px-8 py-5 cursor-pointer"
            [ngClass]="{
              'hover:bg-gray-100 dark:hover:bg-hover':
                !selectedPanel || selectedPanel !== panel.id,
              'bg-[#F3F4F6] dark:bg-hover':
                selectedPanel && selectedPanel === panel.id
            }"
            (click)="goToPanel(panel.id)">
            <mat-icon
              [ngClass]="{
                'text-hint icon-color':
                  !selectedPanel || selectedPanel !== panel.id,
                'text-[#39CB9E] dark:text-primary-500':
                  selectedPanel && selectedPanel === panel.id
              }"
              [svgIcon]="panel.icon"></mat-icon>
            <div class="ml-3">
              <div
                class="font-medium leading-6 settings-section-title"
                [ngClass]="{
                  'text-primary dark:text-primary-500':
                    selectedPanel && selectedPanel === panel.id
                }">
                {{ panel.title | translate }}
              </div>
              <div class="mt-0.5 text-secondary comment-text">
                {{ panel.description | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content class="flex flex-col">
      <!-- Main -->
      <div class="flex-auto px-1">
        <!-- Panel header -->
        <div class="flex items-center justify-between mt-[1.2rem]">
          <!-- Drawer toggle -->
          <button class="" mat-icon-button (click)="drawer.toggle()">
            <mat-icon
              [svgIcon]="'heroicons_outline:menu'"
              class="icon-color"></mat-icon>
          </button>
          <!-- Close button -->
          <button
            class="ml-auto"
            mat-icon-button
            (click)="this._matDialogRef.close()">
            <mat-icon
              class="font-bold icon-size-5 m-2 icon-color"
              aria-label="Close panel">
              close
            </mat-icon>
          </button>
        </div>

        <!-- Load settings panel -->
        <div class="mt-4 px-8">
          <ng-container [ngSwitch]="selectedPanel">
            <!-- Object details -->
            <ng-container *ngSwitchCase="'object-details'">
              <boardObject-details [role]="role"></boardObject-details>
            </ng-container>
            <!-- Access & Invites -->
            <ng-container *ngSwitchCase="'access-invites'">
              <settings-team-board [role]="role"></settings-team-board>
            </ng-container>
            <!-- Notifications -->
            <ng-container *ngSwitchCase="'choose-notifications'">
              <choose-notifications></choose-notifications>
            </ng-container>
            <!-- Custom Fields -->
            <ng-container *ngSwitchCase="'customize-cards-fields'">
              <app-customize-cards-fields></app-customize-cards-fields>
            </ng-container>
            <!-- Templates additional Panels -->
            <!-- Availability -->
            <ng-container *ngSwitchCase="'availability'">
              <template-availability></template-availability>
            </ng-container>
            <!-- Access & invites -->
            <ng-container *ngSwitchCase="'access-template'">
              <template-team></template-team>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
