<div
  class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden relative">
  <mat-drawer-container class="flex-auto sm:h-[95%] bg-white">
    <!-- Drawer -->
    <mat-drawer
      class="sm:w-96 dark:bg-gray-900"
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
      #drawer>
      <!-- Header -->
      <div class="flex items-center justify-between m-8 mr-6 sm:my-10">
        <!-- Title -->
        <div
          class="text-xl w-full h-5 font-semibold tracking-tight leading-none settings-header-text">
          {{ 'PROJECTS_MENU_SETTINGS' | translate }}
        </div>
      </div>
      <!-- Panel links -->
      <div class="flex flex-col divide-y border-t border-b">
        <ng-container *ngFor="let panel of panels; trackBy: trackByFn">
          <div
            class="flex px-8 py-5 cursor-pointer"
            [ngClass]="{
              'hover:bg-gray-100 dark:hover:bg-hover':
                !selectedPanel || selectedPanel !== panel.id,
              'bg-[#F3F4F6] dark:bg-hover':
                selectedPanel && selectedPanel === panel.id
            }"
            (click)="goToPanel(panel.id)">
            <mat-icon
              [ngClass]="{
                'text-hint icon-color':
                  !selectedPanel || selectedPanel !== panel.id,
                'text-primary dark:text-primary-500 text-[#39CB9E]':
                  selectedPanel && selectedPanel === panel.id
              }"
              [svgIcon]="panel.icon"></mat-icon>
            <div class="ml-3">
              <div
                class="font-medium leading-6"
                [ngClass]="{
                  'text-primary dark:text-primary-500 settings-section-title':
                    selectedPanel && selectedPanel === panel.id
                }">
                {{ panel.title | translate }}
              </div>
              <div class="mt-0.5 text-secondary comment-text">
                {{ panel.description | translate }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content
      class="flex flex-col overflow-x-hidden p-[10px] max-h-[94%] mt-2 pb-0 relative">
      <!-- Main -->

      <!-- Panel header -->
      <div
        class="flex flex-row justify-end w-full justify-between items-center mb-6">
        <!-- Close button -->
        <div class="">
          <button
            mat-icon-button
            (click)="
              drawer.opened ? drawer.close() : drawer.open()
            ">
            <mat-icon
              [svgIcon]="'heroicons_outline:menu'"
              class="icon-color"></mat-icon>
          </button>
        </div>
        <!-- Close button -->
        <button mat-icon-button (click)="this._matDialogRef.close()" class="">
          <mat-icon
            class="font-bold icon-size-5 m-1 icon-color"
            aria-label="Close panel"
            [svgIcon]="'heroicons_outline:x'">
            {{ 'CLOSE_TEXT' | translate }}
          </mat-icon>
        </button>
      </div>
      <div class="flex-auto px-7">
        <!-- Load settings panel -->
        <div class="mt-4">
          <ng-container [ngSwitch]="selectedPanel">
            <!-- Object details -->
            <ng-container *ngSwitchCase="'object-details'">
              <object-details></object-details>
            </ng-container>
            <!-- Access & Invites -->
            <ng-container *ngSwitchCase="'access-invites'">
              <settings-team-project></settings-team-project>
            </ng-container>
            <!-- Data Export -->
            <ng-container *ngSwitchCase="'data-export'">
              <data-export></data-export>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
