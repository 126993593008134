<div class="flex flex-col w-96 -m-6">
  <!-- Header -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 border-b border-slate-200 pl-6 sm:pl-8 b">
    <div class="text-lg font-medium">{{'START_VOTING' | translate}}</div>
    <button mat-icon-button [tabIndex]="-1" (click)="this.matDialogRef.close()">
      <mat-icon
        class="text-current"
        [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <!-- Compose form -->
  <form
    class="flex flex-col flex-auto sm:p-8 overflow-y-auto"
    [formGroup]="composeForm">
    <!-- To -->
    <div class="flex justify-between mt-3">
      <div>
        <mat-checkbox
          [color]="'primary'"
          [formControlName]="'checkboxAllColors'">
          {{'ALL_COLORS' | translate}}
        </mat-checkbox>
      </div>

      <div>
        <button mat-stroked-button [matMenuTriggerFor]="labelsMenu">
          <span>{{'COLORS' | translate}}</span>
          <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
        </button>
        <mat-menu #labelsMenu="matMenu" [overlapTrigger]="false">
          <animator-category-selector></animator-category-selector>
        </mat-menu>
      </div>
    </div>

    <mat-form-field class="w-full mt-5">
      <mat-label> {{'VOTES_PER_PARTICIPANTS' | translate}} </mat-label>
      <input
        matInput
        [formControlName]="'votesParticipants'"
        type="number"
        max="200"
        required />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label> {{'MAX_PER_CARD' | translate}} </mat-label>
      <input
        matInput
        [formControlName]="'maxPerCard'"
        type="number"
        max="15"
        required />
    </mat-form-field>
    <!-- Body -->
  </form>

  <!-- Actions -->
  <div
    class="flex justify-end p-4 sm:flex-row sm:items-center border-t border-slate-200 sm:mt-6">
    <div class="flex items-center sm:mt-0">
      <!-- Discard -->
      <button
        class="ml-auto sm:ml-0 btn-tertiary-hover"
        (click)="this.matDialogRef.close()">
        {{'CANCEL' | translate}}
      </button>
      <!-- Save as draft -->
      <button (click)="startVoting()" class="sm:mx-3 btn-primary-hover">
        <span> {{'START_VOTING' | translate}} </span>
      </button>
    </div>
  </div>
</div>
