import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MyNotificationsService } from '@excelway/services/notification.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import { Store } from '@ngrx/store';

import { provideComponentStore } from '@ngrx/component-store';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { AuthStoreSelectors } from 'app/store/auth';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationsStore } from './notifications.store';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
  providers: [provideComponentStore(NotificationsStore)],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  // select logged user
  user: AuthUser;
  private userSubscription: Subscription;
  readonly user$: Observable<AuthUser | null> = this._store.select(
    AuthStoreSelectors.selectLoggedUser
  );
  // notifications store
  notifications$: Observable<any[]>;
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel')
  private _notificationsPanel: TemplateRef<any>;

  // notifications: Notification[];
  notifications: any;
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  userPreferredLanguage: string = 'en';
  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private notificationService: MyNotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private readonly _store: Store,
    private notificationsStore: NotificationsStore
  ) {
    this.notifications$ = this.notificationsStore.notificationsSelector;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get logged user
    this.userSubscription = this.user$.subscribe(user => {
      this.user = user as AuthUser;
      this.userPreferredLanguage = this.user.language;
    });
    this.notificationsStore.getNotifications(this.user.id);

    // Subscribe to notification changes
    this.notifications$.subscribe((notifications: Notification[]) => {
      // Load the notifications
      this.notifications = notifications;

      // Calculate the unread count
      this._calculateUnreadCount();

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    const notificationsIds = this.notifications
      .filter(notification =>
        notification.value.notifiedUsers.some(
          user => user.email === this.user.email && !user.isRead
        )
      )
      .map(notification => ({ id: notification.id }));

    const updateDetails = {
      value: { isRead: true },
      notificationsIds: notificationsIds,
    };

    this.notificationsStore.markAsRead(updateDetails);
  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notificationId: string, notifiedUsers: []): void {
    const currentUserEmail = this.user.email;
    const user: any = notifiedUsers.find(
      (user: any) => user.email === currentUserEmail
    );
    if (!user) {
      return;
    }

    user.isRead = !user.isRead;

    const updateDetails = {
      value: { isRead: user.isRead },
      notificationsIds: [{ id: notificationId }] as [{ id: string }],
    };

    this.notificationsStore.markAsRead(updateDetails);
  }

  /**
   * Delete the given notification
   */
  delete(): void {
    // Delete the notification
    // this._notificationsService.delete(notification.id).subscribe();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  // TODO: Refactor _createOverlay method into a shared service to avoid code duplication across components.
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter(
        notification => !notification.value.notifiedUsers[0].isRead
      ).length;
    }
    this.unreadCount = count;
  }

  removeNotification(notificationId: string): void {
    if (!notificationId) return;
    this.notificationsStore.removeNotification({ notificationId });
  }
}
