/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'My Tasks',
    title: 'MY_TASKS_TAB',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/mytasks',
  },
  {
    id: 'Projects',
    title: 'NAVBAR_PROJECTS',
    type: 'basic',
    icon: 'heroicons_outline:view-list',
    link: '/projects',
    button: {
      title: 'Add Project',
      icon: 'heroicons_outline:plus-sm',
      classes: '',
    },
  },
];
export const adminNavigationItems: any[] = [
  {
    id: 'Workspace',
    title: 'CNDP',
    isAdmin: true,
    type: 'basic',
    icon: 'heroicons_outline:workspace-logo',
    img: 'assets/images/logo/excelway-admin-logo.svg',
  },
  {
    id: 'Members&Teams',
    title: 'NAVBAR_MEMBERS_&_TEAMS',
    color: '#39CB9E',
    isAdmin: true,
    subtitle: '',
    type: 'basic',
    link: 'members&teams',
  },
  {
    id: 'LOGS',
    title: 'NAVBAR_LOG',
    color: '#39CB9E',
    isAdmin: true,
    subtitle: '',
    type: 'basic',
    link: 'log',
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
