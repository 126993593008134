// projects.store.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventTypes } from '@excelway/models/socket-io/event';
import { ApiService } from '@excelway/services/api.service';
import { ObjectService } from '@excelway/services/object.service';
import { ProjectService } from '@excelway/services/project.service';
import { ProjectsService } from '@excelway/services/projects.service';
import { SocketService } from '@excelway/services/socket-io.service';
import { UserWorkspaceService } from '@excelway/services/user-workspace.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import {
  ComponentStore,
  OnStateInit,
  tapResponse,
} from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { CommonService } from 'app/common-dialogs/common.services';
import { AuthStoreSelectors } from 'app/store/auth';
import { ToastrService } from 'ngx-toastr';
import {
  EMPTY,
  Observable,
  catchError,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

export interface ProjectsState {
  projects: any;
  connectedMembers: AuthUser[];
  viewMode: 'cards' | 'list';
}

export const initialState: ProjectsState = {
  projects: null as any,
  connectedMembers: [],
  viewMode: 'cards',
};

@Injectable()
export class ProjectsStore
  extends ComponentStore<ProjectsState>
  implements OnStateInit
{
  projectId = '';
  activeWorkspaceId = this._userWorkspaceService.getActiveWorkspaceId();
  constructor(
    private _projectsService: ProjectsService,
    private _projectService: ProjectService,
    private readonly _objectService: ObjectService,
    private _apiService: ApiService,
    private readonly _socketService: SocketService,
    private readonly _store: Store,
    private router: Router,
    private _userWorkspaceService: UserWorkspaceService,
    private toastr: ToastrService,
    private _commonService: CommonService
  ) {
    super(initialState);
  }

  ngrxOnStateInit(): void {
    this.getProjects();

    if (this.activeWorkspaceId) {
      this.joinRoom(this.activeWorkspaceId);
    }
  }

  flattenProjectsTree(
    projects: any[],
    parentProjectId: string | null = null
  ): any[] {
    let flattenedProjects: any[] = [];

    for (const project of projects) {
      const flattenedProject = {
        ...project,
        parentProjectId,
      };

      flattenedProjects.push(flattenedProject);

      if (project.Project && project.Project.length > 0) {
        flattenedProjects = flattenedProjects.concat(
          this.flattenProjectsTree(project.Project, project.id)
        );
      }
    }

    return flattenedProjects;
  }

  ProjectsWithParentId(
    projects: any[],
    parentProjectId: string | null = null
  ): any[] {
    const projectsList: any[] = [];

    for (const project of projects) {
      const updatedProject = {
        ...project,
        parentId: parentProjectId,
      };

      if (project.Project && project.Project.length > 0) {
        updatedProject.Project = this.ProjectsWithParentId(
          project.Project,
          project.id
        );
      }

      projectsList.push(updatedProject);
    }

    return projectsList;
  }

  // Updaters
  readonly setProjects = this.updater((state, projects: any[]) => ({
    ...state,
    projects,
  }));

  readonly addProject = this.updater((state, project: any) => ({
    ...state,
    projects: [...state.projects, project],
  }));

  readonly updateProject = this.updater((state, updatedProject: any) => ({
    ...state,
    projects: state.projects.map(project =>
      project.id === updatedProject.id
        ? { ...project, ...updatedProject, users: project.users }
        : project
    ),
  }));

  readonly removeProject = this.updater((state, projectId: string) => ({
    ...state,
    projects: state.projects.filter(project => project.id !== projectId),
  }));

  readonly searchProjectsByWord = this.updater((state, word: string) => ({
    ...state,
    projects: state.projects.map(project => {
      if (!project.name?.toLowerCase().includes(word.toLowerCase())) {
        project.isHidden = true;
      } else {
        project.isHidden = false;
      }
      return project;
    }),
  }));

  readonly updateProjectUsers = this.updater(
    (state, { projectId, users }: { projectId: string; users: any[] }) => ({
      ...state,
      projects: state.projects.map(project =>
        project.id === projectId
          ? { ...project, users: [...(project.users || []), ...users] }
          : project
      ),
    })
  );

  readonly removeProjectUser = this.updater(
    (state, { projectId, userId }: { projectId: string; userId: string }) => ({
      ...state,
      projects: state.projects.map(project =>
        project.id === projectId
          ? {
              ...project,
              users: project.users.filter(user => user.id !== userId),
            }
          : project
      ),
    })
  );

  readonly updateProjectIndex = this.updater(
    (state, updatedProjects: any[]) => {
      const transformProjects = (
        projects: any[],
        parentId: string | null = null
      ): any => {
        return projects.map(project => {
          const transformedChildren =
            project.children && project.children.length > 0
              ? transformProjects(project.children, project.data.id)
              : [];

          return {
            ...project.data,
            Project: transformedChildren,
            parentProjectId: parentId,
          };
        });
      };

      const transformedProjects = transformProjects(updatedProjects);

      return {
        ...state,
        projects: transformedProjects,
      };
    }
  );

  // Effects
  readonly getProjects = this.effect(() =>
    this._projectsService.getProjectsTree().pipe(
      tap({
        next: response => {
          const flattenedProjects = this.flattenProjectsTree(response.Project);
          this.setProjects(flattenedProjects);
        },
      }),
      catchError(() => EMPTY)
    )
  );

  readonly udpateProjectsList = this.effect((viewMode$: Observable<string>) => {
    return viewMode$.pipe(
      switchMap(viewMode => {
        if (viewMode === 'cards') {
          return this._projectsService.getProjectsTree().pipe(
            tap({
              next: response => {
                const flattenedProjects = this.flattenProjectsTree(
                  response.Project
                );
                this.setProjects(flattenedProjects);
              },
            }),
            catchError(() => EMPTY)
          );
        } else {
          return this._projectsService.getProjectsTree().pipe(
            // Act on the result within the inner pipe.
            tap({
              next: projects => {
                const projectsList = this.ProjectsWithParentId(
                  projects.Project
                );
                this.setProjects(projectsList);
              },
            }),
            // Handle potential error within the inner pipe.
            catchError(() => EMPTY)
          );
        }
      })
    );
  });

  readonly changeViewMode = this.effect(
    (viewMode$: Observable<'cards' | 'list'>) => {
      return viewMode$.pipe(
        tap((viewMode: 'cards' | 'list') => {
          if (viewMode === 'cards') {
            this.udpateProjectsList('cards');
          }
          if (viewMode === 'list') {
            this.udpateProjectsList('list');
          }
        })
      );
    }
  );

  readonly changeProjectRank = this.effect((project$: Observable<any>) => {
    const activeWorkspaceId = this._userWorkspaceService.getActiveWorkspaceId();
    return project$.pipe(
      switchMap(project =>
        this._apiService
          .changeObjectRank(
            project.projectId,
            project.parentId ? project.parentId : activeWorkspaceId,
            project.parentId ? 'Project' : 'Workspace',
            project.newRank
          )
          .pipe(
            tap({
              next: () => {
                this.udpateProjectsList('list');
              },
            }),
            catchError(() => {
              const errorMessage = 'Rank is out of range';
              this.toastr.error(errorMessage, 'Rank Change Error');
              return EMPTY;
            })
          )
      )
    );
  });

  readonly createProject = this.effect((project$: Observable<any>) => {
    return project$.pipe(
      switchMap(project =>
        this._projectService.createProject(project).pipe(
          tap({
            next: response => {
              const newProject = {
                ...response.projectResponse.createdChildObject,
                icon: response.projectResponse.createdChildObject.value.icon,
                color: response.projectResponse.createdChildObject.value.color,
              };
              this.addProject(newProject);
              this._commonService.triggerInitializeComponent();
              this._socketService.publishEvent({
                roomId: this.activeWorkspaceId ? this.activeWorkspaceId : '',
                eventType: EventTypes.ADD_PROJECT,
                payload: newProject,
              });
              this.router.navigate([
                'projects/project',
                response.projectResponse.createdChildObject.id,
                'workshops',
              ]);
            },
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  readonly duplicateProject = this.effect((project$: Observable<any>) => {
    return project$.pipe(
      switchMap(project => {
        return this._objectService
          .duplicateObject(
            'Workspace',
            [
              {
                objectToDuplicateId: project.id,
                objectParentId: project.parentId
                  ? project.parentId
                  : this.activeWorkspaceId,
              },
            ],
            project.parentId ? project.parentId : this.activeWorkspaceId
          )
          .pipe(
            tap({
              next: response => {
                if (!project.parentId) {
                  this.addProject({
                    id: response.newIds[0],
                    name: project.name + ' (duplicated)',
                    description: null,
                    startDate: null,
                    endDate: null,
                  });
                } else {
                  this.udpateProjectsList('list');
                }
              },
            }),
            catchError(() => {
              return EMPTY;
            })
          );
      })
    );
  });

  readonly searchProject = this.effect((word$: Observable<string>) => {
    return word$.pipe(
      tap(word => {
        this.searchProjectsByWord(word);
      }),
      catchError(err => {
        console.error('An error occurred:', err);
        return EMPTY;
      })
    );
  });

  readonly deleteProject = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      switchMap(id => {
        return this._objectService.deleteObject('project', id).pipe(
          tap(() => {
            // Remove the project locally
            this.removeProject(id);

            // Publish a socket event to notify other users
            this._socketService.publishEvent({
              roomId: this.activeWorkspaceId ? this.activeWorkspaceId : '',
              eventType: EventTypes.DELETE_PROJECT,
              payload: { id },
            });

            // Trigger the initializeComponent method
            this._commonService.triggerInitializeComponent();
          }),
          switchMap(() => {
            // Correct ranks after deletion
            return this._objectService.correctRanks(
              'Project',
              this.activeWorkspaceId ? this.activeWorkspaceId : '',
              false
            );
          }),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    );
  });

  readonly joinRoom = this.effect((roomId$: Observable<string>) => {
    return roomId$.pipe(
      withLatestFrom(
        // Logger user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        ([roomId, user]: [string, AuthUser]) => {
          if (roomId) this._socketService.joinRoom(roomId, user);
        },
        error => console.error(error)
      )
    );
  });

  readonly leaveRoom = this.effect<void>(source$ =>
    source$.pipe(
      withLatestFrom(
        // Room id
        this.select(state => state.projects),
        // Logger user
        this._store.select(AuthStoreSelectors.selectLoggedUser)
      ),
      tapResponse(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, roomId, user]: [void, string, AuthUser]) => {
          if (this.activeWorkspaceId)
            this._socketService.leaveRoom(this.activeWorkspaceId, user);
        },
        error => console.error(error)
      )
    )
  );

  readonly readEvents = this.effect<void>(() =>
    this._socketService.getEvents().pipe(
      tapResponse(
        (event: any) => {
          switch (event.eventType) {
            case EventTypes.ADD_PROJECT:
              this.addProject(event.payload as any);
              this._commonService.triggerInitializeComponent();
              break;
            case EventTypes.DELETE_PROJECT:
              this.removeProject((event.payload as { id: string }).id);
              this._commonService.triggerInitializeComponent();
              break;
            case EventTypes.PROJECT_UPDATED:
              this.updateProject(event.payload as any);
              this._commonService.triggerInitializeComponent();
              break;
            case EventTypes.INVITE_USER:
              this.updateProjectUsers({
                projectId: event.payload.objectId,
                users: event.payload.users,
              });
              break;
            case EventTypes.REMOVE_MEMBER:
              this.removeProjectUser({
                projectId: event.payload.projectId,
                userId: event.payload.userId,
              });
              break;
            case EventTypes.MOVE_PROJECT:
              this.updateProjectIndex(event.payload.projects);
              break;
            case EventTypes.DUPLICATE_PROJECT:
              this.addProject(event.payload as any);
              break;
            default:
              console.warn(
                `Received unexpected event : ${JSON.stringify(event)}`
              );
          }
        },
        error => console.error(error)
      )
    )
  );
}
