<div class="flex flex-col justify-between h-full max-w-[714px] max-h-[467px]">
  <div class="flex flex-0 items-center justify-between">
    <div class="text-[16px] font-semibold text-[#111827] font-inter">
      {{ 'CREATE_A_' | translate }}
      {{ data.objectName.toUpperCase() | translate }}
    </div>
    <button mat-icon-button [tabIndex]="-1"></button>
  </div>
  <mat-dialog-content class="h-fit mat-typography p-0 max-w-[714px] mt-[1rem]">
    <form
      [formGroup]="addObjectForm"
      action="#"
      method="POST"
      class="mx-auto width-[600px] max-w-[600px]">
      <div
        class="relative grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 max-w-[600px] min-w-full">
        <div class="sm:col-span-2 max-w-[600px] min-w-[100%]">
          <label
            for="first-name"
            class="sm:col-span-2 text-base font-semibold leading-6 text-gray-900"
            >{{ 'NAME' | translate }}</label
          >
          <div class="mt-2">
            <input
              formControlName="name"
              type="text"
              name="first-name"
              id="first-name"
              autocomplete="given-name"
              class="block max-w-[600px] min-w-[100%] max-h-[36px] rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6" />
          </div>
        </div>
        <div class="sm:col-span-2 max-w-[600px]">
          <label
            for="message"
            class="block text-base font-semibold leading-6 text-gray-900"
            >{{ 'DESCRIPTION_OF_' | translate }}</label
          >
          <div class="mt-2">
            <textarea
              formControlName="description"
              name="message"
              id="message"
              rows="4"
              class="block max-w-[600px] w-[100%] max-h-[80px] rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"></textarea>
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row sm:items-start justify-between min-w-[600px]">
          <div>
            <label
              for="company"
              class="block text-base font-semibold leading-6 text-gray-900"
              >{{ 'START_DATE' | translate }}
            </label>

            <mat-form-field
              color="accent"
              class="block text-base font-semibold leading-6 text-gray-900 w-[275px] max-h-[36px]">
              <input
                matInput
                formControlName="startDate"
                [matDatepicker]="picker1" />
              <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <label
              for="company"
              class="block text-base font-semibold leading-6 text-gray-900"
              >{{ 'END_DATE' | translate }}
            </label>

            <mat-form-field
              color="accent"
              class="block text-base font-semibold leading-6 text-gray-900 w-[275px] max-h-[36px]">
              <input
                matInput
                [matDatepicker]="picker2"
                formControlName="endDate" />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
              <mat-hint
                *ngIf="
                  addObjectForm.get('endDate')?.hasError('endDateInvalid') &&
                  addObjectForm.get('endDate')?.dirty &&
                  addObjectForm.value.endDate
                "
                ><span class="text-red-400 font-medium"
                  >End Date must be larger than Start Date</span
                ></mat-hint
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="mt-[-1rem]">
    <button
      class="btn-tertiary-hover border-slate-300 font-semibold border flex items-center justify-center max-h-[36px]"
      mat-dialog-close>
      {{ 'CANCEL' | translate }}
    </button>
    <button
      class="btn-primary flex items-center justify-center max-h-[36px]"
      [mat-dialog-close]="true"
      (click)="AddObject()"
      [disabled]="
        !addObjectForm.get('endDate')?.valid && data.isEndDateMandatory
      ">
      {{ 'SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
