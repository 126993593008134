export interface ReceivedEvent {
  eventType: string;
  payload: object;
}

export interface SentEvent {
  roomId: string;
  eventType: string;
  payload: object;
}

// event-types.ts
export const EventTypes = {
  PROJECT_UPDATED: 'projectUpdated',
  ADD_PROJECT: 'add-project',
  DELETE_PROJECT: 'delete-project',
  INVITE_USER: 'invite-user',
  REMOVE_MEMBER: 'remove-member',
  MOVE_PROJECT: 'move-project',
  DUPLICATE_PROJECT: 'duplicate-project',
  BOARD_UPDATED: 'update-board',
  DUPLICATE_BOARD: 'duplicate-board',
  MOVE_BOARD: 'move-board',
  DRAG_AND_DROP_BOARD: 'drag-and-drop-board',
  // Add other event types here
};
