import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { FuseConfirmationService } from '@fuse/services/confirmation';

import { colors } from '@excelway/constants/color';
import { CURRENCIES } from '@excelway/constants/currencies';
import { DURATION_UNITS } from '@excelway/constants/duration-units';
import { UpdatePayload } from '@excelway/models/api/update-payload';
import { UpdateResponse } from '@excelway/models/api/update-response';
import { EventTypes, SentEvent } from '@excelway/models/socket-io/event';
import { ApiService } from '@excelway/services/api.service';
import { SocketService } from '@excelway/services/socket-io.service';
import { provideComponentStore } from '@ngrx/component-store';
import { CommonService } from 'app/common-dialogs/common.services';
import { ModernStore } from 'app/layout/layouts/horizontal/modern/modern.store';
import { BoardStore } from '../../board/board.store';

@Component({
  selector: 'boardObject-details',
  templateUrl: './object-details.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe, provideComponentStore(ModernStore)],
})
export class ObjectDetailsComponent implements OnInit {
  accountForm: UntypedFormGroup;
  currencies = CURRENCIES;
  durationUnits = DURATION_UNITS;
  colors = colors;
  public dialog: MatDialog;

  objectSettingsForm = new FormGroup({
    name: new FormControl(''),
    currency: new FormControl(''),
    durationUnit: new FormControl(''),
    description: new FormControl(''),
    startDate: new FormControl<Date>(new Date()),
    endDate: new FormControl(),
    isPublic: new FormControl<boolean>(false),
    color: new FormControl(''),
    value: new FormControl({}),
  });

  templateSettingsForm = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    longDescription: new FormControl(''),
    category: new FormControl(''),
  });
  @Input() role: string;

  /**
   * Constructor
   */
  constructor(
    public _matDialogRef: MatDialogRef<ObjectDetailsComponent>,
    private _commonService: CommonService,
    private datePipe: DatePipe,
    private readonly _modernStore: ModernStore,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private _fuseConfirmationService: FuseConfirmationService,
    private readonly _store: BoardStore,
    private readonly _apiService: ApiService,
    private readonly socketService: SocketService
  ) {}

  // data is the object
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    //Board Fields
    if (!this.data.value?.isTemplate) {
      this.objectSettingsForm.controls['name'].setValue(this.data.name);
      this.objectSettingsForm.controls['description'].setValue(
        this.data.description
      );
      this.objectSettingsForm.controls['currency'].setValue(this.data.currency);
      this.objectSettingsForm.controls['startDate'].setValue(
        this.data.startDate
      );
      this.objectSettingsForm.controls['endDate'].setValue(this.data.endDate);
      this.objectSettingsForm.controls['durationUnit'].setValue(
        this.data.durationUnit
      );
      this.objectSettingsForm.controls['color'].setValue(this.data.color);
    }

    //Template Fields
    if (this.data.value?.isTemplate) {
      this.templateSettingsForm.controls['name'].setValue(this.data.name);
      this.templateSettingsForm.controls['description'].setValue(
        this.data.description
      );
      this.templateSettingsForm.controls['longDescription'].setValue(
        this.data.value.longDescription
      );
      this.templateSettingsForm.controls['category'].setValue(
        this.data.value.category
      );
    }
  }

  selectColor(color: string): void {
    this.objectSettingsForm.controls['color'].setValue(color);
  }

  //Update Board
  saveChanges(): void {
    const objectData = {
      name: this.objectSettingsForm.value.name,
      description: this.objectSettingsForm.value.description,
      currency: this.objectSettingsForm.value.currency,
      durationUnit: this.objectSettingsForm.value.durationUnit,
      color: this.objectSettingsForm.value.color,
      startDate: this.datePipe.transform(
        this.objectSettingsForm.value.startDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        this.objectSettingsForm.value.endDate,
        'yyyy-MM-dd'
      ),
      isPublic: true,
      isPrivate: false,
      status: 'planned',
    };
    this._commonService
      .updateObjectDetails('Board', objectData, this.data.id)
      .subscribe(() => {
        //this._matDialogRef.close(this.objectSettingsForm.value.name);
        const event: SentEvent = {
          roomId: this.data.projectId,
          eventType: EventTypes.BOARD_UPDATED,
          payload: { id: this.data.id, ...objectData },
        };
        this.socketService.publishEvent(event);
      });
    this._matDialogRef.close(this.objectSettingsForm.value.name);
  }

  //Update Template
  updateTemplateField(fieldName: string, value: any): void {
    if (this.templateSettingsForm) {
      const formControl = this.templateSettingsForm.get(fieldName);
      if (formControl) {
        formControl.patchValue(value);
        const payload: UpdatePayload = {
          roleType: 'Board',
          objectId: this.data.id,
          body: { [fieldName]: value },
        };

        this._apiService
          .updateObject(payload)
          .subscribe((response: UpdateResponse) => {
            return response;
          });
      }
    }
  }

  /**
   * Delete board
   */
  openDeleteConfirmation(): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: 'Delete board',
      message:
        'Are you sure you want to delete this board? All of your data will be permanently removed. This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
      },
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((confirmed: string) => {
      if (confirmed === 'confirmed') {
        this.deleteSelectedBoard();
        this._matDialogRef.close();
        this.goBack();
      }
    });
  }

  saveAsTemplate(): void {
    const payload = {
      projectId: this.data.parentId,
      boardId: this.data.id,
    };
    this._matDialogRef.close(payload);
  }
  deleteSelectedBoard(): void {
    this._store.removeBoardEffect(this.data.id);
  }

  goBack(): void {
    this._modernStore.goBack();
  }
}
