import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ObjectDetailsComponent } from './object-details/object-details.component';
import { ProjectSettingsComponent } from './project-settings.component';
import { SettingsTeamComponent } from './team/team.component';

import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { TagychipsModule } from '@excelway/components/tagychips/tagychips.module';
import { EnableByRoleDirective } from '@excelway/directives/enable-by-role.directive';
import { FuseAlertModule } from '@fuse/components/alert';
import { SharedModule } from 'app/shared/shared.module';
import { ProjectsStore } from '../projects.store';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ProjectSettingsComponent,
    ObjectDetailsComponent,
    SettingsTeamComponent,
  ],
  providers: [ProjectsStore],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,

    MatFormFieldModule,

    MatInputModule,
    MatRadioModule,

    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    FuseAlertModule,
    SharedModule,
    MatDatepickerModule,
    TagychipsModule,
    EnableByRoleDirective,
    TranslateModule,
  ],
  exports: [ProjectSettingsComponent],
})
export class ProjectSettingsModule {}
