import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslationService } from '@excelway/services/translation.service';
import { Field } from 'app/modules/user-interface/user-interface.store';

@Component({
  selector: 'app-create-object',
  templateUrl: './create-object.component.html',
})
export class CreateObjectComponent {
  interfaceId = 'cm2yo8ux500hqg04cv2go0nco';
  fields: Field[] = [];
  @Input()
  objects: any[] = [];

  addObjectForm = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date(), [this.endDateValidator]),
    value: new FormControl({}),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public _matDialogRef: MatDialogRef<CreateObjectComponent>,
    private datePipe: DatePipe,
    public _formBuilder: FormBuilder,
    private _translationService: TranslationService
  ) {
    this._translationService
      .loadComponentTranslations(this.interfaceId)
      .subscribe(fields => {
        this.fields = fields;
      });
  }

  @Input()
  objectName: string = '';

  @Output() objectCreated = new EventEmitter<any>();

  endDateValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const startDate = new Date(control.parent?.get('startDate')?.value);
    const endDate = new Date(control.value);

    if (endDate < startDate) {
      return { endDateInvalid: true };
    }

    return null;
  }

  AddObject(): any {
    const objectData = {
      name: this.addObjectForm.value.name,
      description: this.addObjectForm.value.description,
      startDate: this.datePipe.transform(
        this.addObjectForm.value.startDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        this.addObjectForm.value.endDate,
        'yyyy-MM-dd'
      ),
    };
    this._matDialogRef.close(objectData);
  }
}
