import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateResponse } from '@excelway/models/api/update-response';
import { CommonService } from 'app/common-dialogs/common.services';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  of,
  tap,
  throwError,
} from 'rxjs';
import { Team } from './models/teams-scope.model';
import { User, UserInfo } from './models/users-scope.model';

@Injectable({
  providedIn: 'root',
})
export class AdminConsoleService {
  private usersTeamInfoSubject = new BehaviorSubject<any[]>([]);
  private teams = new BehaviorSubject<any[]>([]);
  teams$ = this.teams.asObservable();
  usersTeamInfo$ = this.usersTeamInfoSubject.asObservable();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService
  ) {}

  getUsersWithTeams = (workspaceId: string): Observable<any[]> => {
    return this._http
      .get<any[]>(
        `${environment.backendUrl}/v1/members_usergroups/${workspaceId}`,
        {
          withCredentials: true,
        }
      )
      .pipe(
        catchError(() => of([])),
        tap(users => this.usersTeamInfoSubject.next(users))
      );
  };

  updateObjectDetails(
    objectRoleType: string,
    objectData: any,
    objectId: string,
    workspaceId: string
  ): Observable<any> {
    if (workspaceId) {
      return this._http
        .put(
          `${environment.backendUrl}/v1/${objectRoleType}/${objectId}`,
          objectData,
          { withCredentials: true }
        )
        .pipe(
          tap(() => {
            this.getUsersWithTeams(workspaceId).subscribe();
          })
        );
    } else {
      return throwError('Workspace ID is not available');
    }
  }
  getTeamsWithMemberCounts(context: string): Observable<Team[]> {
    return this._http
      .get<any>(`${environment.backendUrl}/v1/userGroups/${context}`, {
        withCredentials: true,
      })
      .pipe(
        catchError(() => of([])),
        tap(teams => this.teams.next(teams))
      );
  }
  addMembers(
    roleTypeObject: string,
    roleTypeObjectId: string,
    membersData: any
  ): Observable<any> {
    const url = `${environment.backendUrl}/v1/${roleTypeObject}/${roleTypeObjectId}/add_members`;

    return this._http
      .patch<any>(url, membersData, {
        withCredentials: true,
      })
      .pipe(
        catchError(error => {
          console.error('Error adding members:', error);
          return throwError(error);
        })
      );
  }

  createUsers(emails: string[]): Observable<any> {
    const url = `${environment.kratosUpdaterUrl}/v1/createUsers`;
    const body = { emails };

    return this._http
      .post(url, body, {
        withCredentials: true,
      })
      .pipe(
        catchError(() => of([])),
        tap(users => this.usersTeamInfoSubject.next(users))
      );
  }

  createUserGroup(
    name: string,
    color: string,
    workspaceId: string,
    invitedUsers?: any[]
  ): Observable<any> {
    const url = `${environment.backendUrl}/v1/createUserGroup`;
    const body = { name, invitedUsers, color, workspaceId };

    return this._http.post(url, body, { withCredentials: true }).pipe(
      tap((newTeam: any) => {
        this.getTeamsWithMemberCounts(workspaceId).subscribe();
        const currentTeams = this.teams.value;
        newTeam.memberCount = invitedUsers ? invitedUsers.length : 0;
        this.teams.next([...currentTeams, newTeam]);
      })
    );
  }

  updateUserGroup(
    id: string,
    payload: { value: { name: string; color: string } },
    workspaceId: string
  ): Observable<UpdateResponse> {
    if (workspaceId) {
      return this._http
        .put<UpdateResponse>(`${environment.backendUrl}/v1/${id}`, payload, {
          withCredentials: true,
        })
        .pipe(
          tap(() => {
            this.getTeamsWithMemberCounts(workspaceId).subscribe();
          })
        );
    } else {
      return throwError('Workspace ID is not available');
    }
  }

  getUserGroupMembers = (id: string): Observable<any[]> => {
    return this._http.get<User[]>(
      `${environment.backendUrl}/v1/UserGroup/${id}/members`,
      {
        withCredentials: true,
      }
    );
  };

  getUserGroupObjects = (
    id: string,
    roleTypeName: string
  ): Observable<any[]> => {
    return this._http.get<any[]>(
      `${environment.backendUrl}/v1/UserGroup/${id}/${roleTypeName}`,
      {
        withCredentials: true,
      }
    );
  };

  inviteMemberToTeam = (
    roleTypeName: string,
    userGroupId: string,
    member: UserInfo
  ): Observable<any> => {
    return this._http.post<UserInfo>(
      `${environment.backendUrl}/v1/inviteMember/${userGroupId}/${roleTypeName}`,
      member,
      {
        withCredentials: true,
      }
    );
  };

  removeMember = (
    body: { userId: string },
    userGroupId: string
  ): Observable<any> => {
    return this._http.patch<any>(
      `${environment.backendUrl}/v1/${userGroupId}/delete_member`,
      body,
      {
        withCredentials: true,
      }
    );
  };

  relateObjectWithUserGroup = (
    userGroupId: string,
    objectId: string,
    roleTypeName: string,
    body: { role: string }
  ): Observable<any> => {
    return this._http
      .post<any>(
        `${environment.backendUrl}/v1/${userGroupId}/${objectId}`,
        body,
        {
          withCredentials: true,
        }
      )
      .pipe(
        tap(response => {
          const redirectUrl = response.redirectUrl;
          const invitedUsers = response.members;

          this._commonService.sendEmail(
            invitedUsers,
            objectId,
            roleTypeName,
            redirectUrl
          );
        })
      );
  };

  updateUserGroupAccessLevelToObject = (
    roleTypeObjectId: string,
    body: { role: string; userGroupId?: string; userId?: string }
  ): Observable<any> => {
    return this._http.patch<any>(
      `${environment.backendUrl}/v1/${roleTypeObjectId}/update_object_accesslevel`,
      body,
      {
        withCredentials: true,
      }
    );
  };

  removeObjectFromUserGroupOrUser = (
    roleTypeObjectId: string,
    body: { userId?: string; userGroupId?: string }
  ): Observable<any> => {
    return this._http.patch<any>(
      `${environment.backendUrl}/v1/${roleTypeObjectId}/remove_access_to_object`,
      body,
      {
        withCredentials: true,
      }
    );
  };

  removeObject = (objectId: string): Observable<any> => {
    return this._http
      .delete<any>(`${environment.backendUrl}/v1/${objectId}`, {
        withCredentials: true,
      })
      .pipe(
        tap(() => {
          const currentTeams = this.teams.value;
          this.teams.next(currentTeams.filter(team => team.id !== objectId));
        })
      );
  };

  removeUserFromUserGroup = (
    userGroupId: string,
    userId: string
  ): Observable<any> => {
    return this._http.delete<any>(
      `${environment.backendUrl}/v1/${userId}/${userGroupId}/remove_membership`,
      {
        withCredentials: true,
      }
    );
  };

  getUsersObjects = (
    userId: string,
    roleTypeName: string,
    workspaceId: string
  ): Observable<any[]> => {
    return this._http.get<any[]>(
      `${environment.backendUrl}/v1/User/${workspaceId}/${userId}/${roleTypeName}`,
      {
        withCredentials: true,
      }
    );
  };

  getAllUsers = (workspaceId: string): Observable<any[]> => {
    return this._http.get<any[]>(
      `${environment.backendUrl}/v1/User/${workspaceId}`,
      {
        withCredentials: true,
      }
    );
  };

  inviteUserToObject(
    userId: string,
    objectId: string,
    body: { role: string; workspaceId: string },
    invitedUsers: any[],
    roleTypeName: string
  ): Observable<any> {
    return this._http
      .post<any>(
        `${environment.backendUrl}/v1/${userId}/${objectId}/invite_user_to_object`,
        body,
        {
          withCredentials: true,
        }
      )
      .pipe(
        tap(response => {
          const redirectUrl = response.redirectUrl;

          this._commonService.sendEmail(
            invitedUsers,
            objectId,
            roleTypeName,
            redirectUrl
          );
        })
      );
  }

  getUserTeams = (userId: string): Observable<any[]> => {
    return this._http.get<any[]>(
      `${environment.backendUrl}/v1/${userId}/user_usergroups`,
      {
        withCredentials: true,
      }
    );
  };

  inviteMultipeUsers = (body: {
    roleTypeId: string;
    roleTypeName: string;
    users: { id: string; email: string; redirectTo: string }[];
    role: string;
  }): Observable<any> => {
    return this._http.post<any>(
      `${environment.backendUrl}/v1/add-multiple-users`,
      body,
      {
        withCredentials: true,
      }
    );
  };

  desactivateMemberAccount = (userId: string): Observable<any> => {
    return this._http.patch<any>(
      `${environment.backendUrl}/v1/${userId}/disactivate`,
      {
        userId: userId,
      },
      {
        withCredentials: true,
      }
    );
  };
}
