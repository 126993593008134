import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { TagychipsModule } from '@excelway/components/tagychips/tagychips.module';
import { FuseAlertModule } from '@fuse/components/alert';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared/shared.module';
import { AccessInvitesComponent } from './access-and-invites/access-and-invites.component';
import { DataExportComponent } from './data-export/data-export.component';
import { ObjectDetailsComponent } from './object-details/object-details.component';
import { ObjectSettingsComponent } from './settings.component';
import { objectSettingsRoutes } from './settings.routing';
import { SettingsTeamComponent } from './team/team.component';

@NgModule({
  declarations: [
    ObjectSettingsComponent,
    ObjectDetailsComponent,
    AccessInvitesComponent,
    DataExportComponent,
    SettingsTeamComponent,
  ],
  imports: [
    RouterModule.forChild(objectSettingsRoutes),
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    FuseAlertModule,
    SharedModule,
    MatDatepickerModule,
    TagychipsModule,
    TranslateModule,
  ],
  exports: [
    ObjectSettingsComponent,
    AccessInvitesComponent,
    ObjectDetailsComponent,
    DataExportComponent,
    SettingsTeamComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_LUXON_DATE_FORMATS,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class ObjectSettingsModule {}
