import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { TranslationService } from '@excelway/services/translation.service';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslateService } from '@ngx-translate/core';
import { Field } from 'app/modules/user-interface/user-interface.store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSettingsComponent implements OnInit, OnDestroy {
  fields: Field[] = [];
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  panels: any[] = [];
  selectedPanel: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    private _changeDetectorRef: ChangeDetectorRef,
    public _matDialogRef: MatDialogRef<ObjectSettingsComponent>,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _TranslationService: TranslationService,
    private translate: TranslateService
  ) {
    // Initialize the selected panel based on the provided data
    if (data.tabToOpen) {
      this.selectedPanel = this.data.tabToOpen;
    } else {
      this.selectedPanel = 'object-details';
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Setup available panels
    this.panels = [
      {
        id: 'object-details',
        icon: 'heroicons_outline:information-circle',
        title: this.translate.instant('WORKSHOP_DETAILS_TITLE'),
        description: this.translate.instant('WORKSHOP_DETAILS_DESCRIPTION'),
      },
      {
        id: 'access-invites',
        icon: 'heroicons_outline:user-group',
        title: this.translate.instant('ACCESS_INVITES'),
        description: this.translate.instant('ACCESS_INVITES_DESCRIPTION'),
      },
      // {
      //   id: 'data-export',
      //   icon: 'heroicons_outline:chart-square-bar',
      //   title: 'Data Export',
      //   description: 'Manage your files',
      // },
    ];

    // implementing translation
    this._TranslationService.loadTranslationsSubscriptionVersion(
      'cm1ql0nfs003qg0btww780zqt'
    );

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Set the drawerMode and drawerOpened
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        } else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    if (this.data.tabToOpen == 'data-export') {
      this.selectedPanel = 'access-invites';
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Navigate to the panel
   *
   * @param panel
   */
  goToPanel(panel: string): void {
    this.selectedPanel = panel;

    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }

  /**
   * Get the details of the panel
   *
   * @param id
   */
  getPanelInfo(id: string): any {
    return this.panels.find(panel => panel.id === id);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
