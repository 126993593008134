import { COMMA, ENTER } from '@angular/cdk/keycodes';

export const ownerInputConfig = {
  label: '',
  placeholder: 'select owner',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 100,
  addOnBlur: true,
  enableAvatar: true,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: true,
};
export const membersInputConfig = {
  label: 'Members',
  placeholder: 'select Member',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 10,
  addOnBlur: true,
  enableAvatar: true,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: false,
};
export const SectionsInputConfig = {
  label: 'Section',
  placeholder: 'select Section',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 1,
  addOnBlur: true,
  enableAvatar: false,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: false,
};
export const boardsInputConfig = {
  label: 'Boards',
  placeholder: 'select Section',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 10,
  addOnBlur: true,
  enableAvatar: false,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: false,
};
export const priorityInputConfig = {
  label: 'Priority',
  placeholder: '',
  headerButton: '',
  secondaryPlaceholder: '',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 1,
  addOnBlur: true,
  enableAvatar: false,
  enableColors: true,
  enableMultipleLines: true,
  backgroundColor: '#FFE2E5',
  color: '#FFE2E5',
  enableFilterAfterSelect: true,
  inviteByEmail: false,
};
export const tagsInputConfig = {
  label: 'Tags',
  placeholder: '',
  headerButton: '+ Add',
  secondaryPlaceholder: '',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 10,
  addOnBlur: true,
  enableAvatar: false,
  enableColors: true,
  enableMultipleLines: false,
  backgroundColor: '#FFE2E5',
  color: '#FFE2E5',
  enableFilterAfterSelect: true,
  inviteByEmail: false,
};
export const QuillConfiguration = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ['link'],
    ['clean'],
  ],
};

export const coEditorsInputConfig = {
  label: 'Co-Editors',
  placeholder: 'select a co-editor',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 1,
  addOnBlur: true,
  enableAvatar: true,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: true,
};

export const adminConsoleObjectsAssignment = {
  label: 'Objects',
  placeholder: 'Select object',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 1,
  addOnBlur: true,
  enableAvatar: false,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: false,
};

export const membersInvitation = {
  label: 'Objects',
  placeholder: 'Select object',
  headerButton: '',
  secondaryPlaceholder: 'Add another object',
  appearance: 'fill',
  separatorKeyCodes: [ENTER, COMMA],
  maxSelectedOptions: 20,
  addOnBlur: true,
  enableAvatar: true,
  enableColors: true,
  enableMultipleLines: true,
  enableFilterAfterSelect: true,
  inviteByEmail: true,
};
