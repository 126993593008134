import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CreateTemplateBoard } from '@excelway/models/board/board.model';
import { Observable } from 'rxjs';
import { UpdatedAvailability } from '@excelway/types/template-settings.types';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private _httpClient: HttpClient) {}

  createCustomTemplateBoard(
    projectId: string,
    data: CreateTemplateBoard
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/board-template/v1/new-template/${projectId}`,
      data,
      { withCredentials: true }
    );
  }

  getProjectAvailableTemplateBoards(
    projectId: string,
    excelwayTemplateLanguage: string
  ): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/board-template/v1/template/list/${projectId}/${excelwayTemplateLanguage}`,
      {
        withCredentials: true,
      }
    );
  }

  toggleBoardTemplateCreation(
    projectId: string,
    templateId: string,
    objectInput?: {
      name: string;
      description?: string;
      startDate?: Date;
      endDate?: Date;
    }
  ): Observable<any> {
    // Flatten objectInput before sending it
    const requestPayload = objectInput
      ? {
          name: objectInput.name,
          description: objectInput.description,
          startDate: objectInput.startDate,
          endDate: objectInput.endDate,
        }
      : {};

    return this._httpClient.post(
      `${environment.backendUrl}/board-template/v1/toggle/${projectId}/${templateId}`,
      requestPayload,
      {
        withCredentials: true,
      }
    );
  }

  getTemplateAvailability(templateId: string): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.backendUrl}/board-template/v1/template/availability/${templateId}`,
      {
        withCredentials: true,
      }
    );
  }

  updateTemplateAvailability(
    templateId: string,
    data: UpdatedAvailability
  ): Observable<any> {
    return this._httpClient.patch(
      `${environment.backendUrl}/board-template/v1/availability/${templateId}`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  duplicateExcelwayTemplate(
    projectId: string,
    excelwayTemplateId: string
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.backendUrl}/board-template/v1/duplicate-excelway-template/${projectId}/${excelwayTemplateId}`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
