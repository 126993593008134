<div class="w-full max-w-8xl">
  <!-- Form -->
  <form [formGroup]="accessInvitesForm">
    <!-- Section -->
    <div class="w-full">
      <div class="text-xl">{{ 'CHANGE_ACCESS_TITLE' | translate }}</div>
    </div>
    <div class="grid sm:grid-cols-2 gap-6 w-full mt-8">
      <!-- Plan -->
      <mat-radio-group
        class="invisible absolute w-0 h-0 pointer-events-none"
        [formControlName]="'plan'"
        #planRadioGroup="matRadioGroup">
        <ng-container *ngFor="let plan of plans; trackBy: trackByFn">
          <mat-radio-button [value]="plan.value"></mat-radio-button>
        </ng-container>
      </mat-radio-group>
      <ng-container *ngFor="let plan of plans; trackBy: trackByFn">
        <div
          class="relative flex flex-col items-start justify-start p-6 rounded-md shadow cursor-pointer bg-card"
          [ngClass]="{
            'ring ring-inset ring-[#39CB9E]':
              planRadioGroup.value === plan.value
          }"
          (click)="
            planRadioGroup.value = plan.value;
            plan.value === 'public' ? (isPublic = true) : (isPublic = false)
          ">
          <ng-container *ngIf="planRadioGroup.value === plan.value">
            <mat-icon
              class="absolute top-0 right-0 mt-3 mr-3 icon-size-7 text-primary text-[#39CB9E]"
              [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
          </ng-container>
          <mat-icon class="icon-color">{{ plan.icon }}</mat-icon>
          <div class="mt-1 font-semibold label font-bold">
            {{ plan.label }}
          </div>
          <div class="mt-1 whitespace-normal text-secondary comment-text">
            {{ plan.details }}
          </div>
          <div class="flex-auto"></div>
        </div>
      </ng-container>
    </div>

    <!-- Divider -->
    <div class="mt-12 mb-10 border-t"></div>

    <!-- Members -->
    <settings-team-common></settings-team-common>

    <!-- Divider -->
    <div class="mt-11 mb-10 border-t"></div>

    <!-- Actions -->
    <div class="flex items-center justify-end">
      <button (click)="saveChanges()" class="btn-primary-hover" type="button">
        {{ 'Save' | translate }}
      </button>
    </div>
  </form>
</div>
