import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '@excelway/services/translation.service';
import { Field } from 'app/modules/user-interface/user-interface.store';
import { Subscription } from 'rxjs';
import { WorkshopService } from '../workshop.activities';

@Component({
  selector: 'start-votes-dialog',
  templateUrl: './start-votes-dialog.html',
  providers: [DatePipe],
})
export class StartVotesDialogComponent implements OnInit, OnDestroy {
  fields: Field[] = [];
  private subscription: Subscription;

  startvotingform = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    startDate: new FormControl<Date>(new Date()),
    endDate: new FormControl(),
    startTime: new FormControl<string>(''),
    endTime: new FormControl(),
    isPublic: new FormControl<boolean>(false),
    value: new FormControl({}),
  });

  update = true;
  board: boolean;
  workshop: [{ isPrivate: true }];
  createActivity: boolean;
  workshopId = '';
  isPublic = true;
  composeForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    public _formbuilder: FormBuilder,
    private datePipe: DatePipe,
    private _Activatedroute: ActivatedRoute,
    private _workshopService: WorkshopService,
    private _formBuilder: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<StartVotesDialogComponent>,
    private _translationService: TranslationService
  ) {}
  ngOnInit(): void {
    // Create the form
    this.composeForm = this._formBuilder.group({
      checkboxAllColors: [''],
      votesParticipants: [''],
      maxPerCard: [''],
    });
    console.log(this.data);
    this.startvotingform.controls['name'].setValue(this.data.name);
    this.startvotingform.controls['description'].setValue(
      this.data.description
    );
    this.startvotingform.controls['startDate'].setValue(this.data.startDate);
    this.startvotingform.controls['endDate'].setValue(this.data.endDate);
    this.startvotingform.controls['startTime'].setValue(
      this.data.value.startTime
    );
    this.startvotingform.controls['endTime'].setValue(this.data.value.endTime);
    this.isPublic = this.data.value.isPublic;
  }

  saveChanges(): void {
    const activityData = {
      name: this.startvotingform.value.name,
      description: this.startvotingform.value.description,
      startDate: this.datePipe.transform(
        this.startvotingform.value.startDate,
        'yyyy-MM-dd'
      ),
      endDate: this.datePipe.transform(
        this.startvotingform.value.endDate,
        'yyyy-MM-dd'
      ),
      startTime: this.startvotingform.value.startTime,
      endTime: this.startvotingform.value.endTime,
      isPublic: this.isPublic,
      isPrivate: false,
    };
    this._workshopService
      .updateActivity(activityData, this.data.id)
      .subscribe();
  }

  changeIsPublic(isPublic: boolean): void {
    if (isPublic) {
      this.isPublic = true;
    } else {
      this.isPublic = false;
    }
  }

  startVoting(): void {
    const activityData = {
      workFlowStep: 'vote',
      maxVote: this.composeForm.get('maxPerCard')?.value,
      maxVoteParticipant: this.composeForm.get('votesParticipants')?.value,
      isPublic: true,
      value: { priority: 'low' },
    };
    this._workshopService
      .updateActivity(activityData, this.data.boardId)
      .subscribe();
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
