import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreatedChildObject } from '@excelway/models/api/created-child-object';
import {
  CardModelResponse,
  SubCardModelResponse,
  Tag,
} from '@excelway/models/board/card.model';
import { UserModel } from '@excelway/models/user/user.model';
import { CardService } from '@excelway/services/card.service';
import { ObjectService } from '@excelway/services/object.service';
import { SocketService } from '@excelway/services/socket-io.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { AuthStoreSelectors } from 'app/store/auth';
import {
  EMPTY,
  Observable,
  catchError,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

interface CardState {
  card: CardModelResponse;
  isParent: boolean;
  parentId?: string;
  parentName?: string;
}

export const initialState: CardState = {
  card: null as any,
  isParent: false,
};

@Injectable()
export class CardStore extends ComponentStore<CardState> {
  constructor(
    private readonly _store: Store,
    private readonly _objectService: ObjectService,
    private readonly _socketService: SocketService,
    private readonly _cardService: CardService,
    private readonly _route: ActivatedRoute
  ) {
    super(initialState);
  }

  //**************************************************effects***************************************

  readonly getCard = this.effect(
    (
      card$: Observable<{
        id: string;
        isParent: boolean;
        parentName?: string;
        parentId?: string;
      }>
    ) => {
      return card$.pipe(
        // 👇 Handle race condition with the proper choice of the flattening operator.
        switchMap(({ id, isParent, parentName, parentId }) =>
          this._cardService.getCard(id).pipe(
            //👇 Act on the result within inner pipe.
            tap({
              next: card => {
                this.setCard({ card, isParent, parentName, parentId });
              },
            }),
            // 👇 Handle potential error within inner pipe.
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly deleteChecklist = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      switchMap(id => {
        return this._objectService.deleteObject('Checklist', id).pipe(
          tap(() => {
            this.removeChecklist(id);
          }),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    );
  });
  readonly updateCard = this.effect(
    (
      card$: Observable<{
        cardId: string;
        roleType: string;
        body: any;
      }>
    ) => {
      return card$.pipe(
        switchMap(({ cardId, roleType, body }) =>
          this._cardService
            .updateCard({ objectId: cardId, roleType, body })
            .pipe(
              tap(updatedCard => {
                this.setUpdatedCard(updatedCard);
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  readonly updateTag = this.effect(
    (
      card$: Observable<{
        tagId: string;
        roleType: string;
        body: { name?: string; color?: string };
      }>
    ) => {
      return card$.pipe(
        switchMap(({ tagId, roleType, body }) =>
          this._cardService.updateTag({ objectId: tagId, roleType, body }).pipe(
            tap(updatedtag => {
              this.setUpdatedTag(updatedtag);
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );
  readonly updateCardMember = this.effect(
    (member$: Observable<{ memberId: string }>) => {
      return member$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(([member, cardId]) =>
          this._cardService
            .addCardMember(cardId as string, member.memberId)
            .pipe(
              tap(newCardMember => {
                // TODO UPDATE CARD AFTER ADD NEW MEMBER
                this.setUpdatedCard(newCardMember);
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  readonly updateCardResponsible = this.effect(
    (
      payload$: Observable<{
        responsible: UserModel[];
        cardId: string;
      }>
    ) => {
      return payload$.pipe(
        switchMap(({ responsible, cardId }) =>
          this._cardService
            .assignCardResponsible(responsible[0].id as string, cardId)
            .pipe(
              tap(updatedCard => {
                this.setUpdatedCard(updatedCard);
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  readonly assignTagToCard = this.effect(
    (
      payload$: Observable<{
        tagId: string;
        tagColor: string;
        tagName: string;
      }>
    ) => {
      return payload$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(([{ tagId, tagColor, tagName }, cardId]) =>
          this._cardService.assignedTagToCard(tagId, cardId as string).pipe(
            tap(() => {
              this.setCardNewTag({ id: tagId, color: tagColor, name: tagName });
            }),
            catchError(() => EMPTY)
          )
        )
      );
    }
  );

  readonly addCardAttachment = this.effect(
    (
      payload$: Observable<{
        file: any;
        attachmentDetails: {
          createdAt: string;
          size: string;
          documentType: string;
        };
      }>
    ) => {
      return payload$.pipe(
        withLatestFrom(this.select(state => state.card?.id)),
        switchMap(([{ file, attachmentDetails }, cardId]) =>
          this._cardService
            .addCardAttachment(cardId as string, file, attachmentDetails)
            .pipe(
              tap(file => {
                this.setCardNewAttachement(file.createdChildObject);
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  readonly deleteCardAttachment = this.effect((id$: Observable<string>) => {
    return id$.pipe(
      switchMap(id => {
        return this._objectService.deleteObject('document', id).pipe(
          tap(() => {
            this.removeAttachment(id);
          }),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    );
  });

  // Effect for create a new checklist
  readonly addChecklist = this.effect((checklistName$: Observable<string>) => {
    return checklistName$.pipe(
      withLatestFrom(this.select(state => state.card?.id)),
      switchMap(([checklistName, cardId]: [string, string]) =>
        this._cardService.createChecklist(checklistName, cardId).pipe(
          tap({
            next: response => {
              const checklist = response.createdChildObject;
              // Add new checklist to state
              this.addNewChecklist(checklist);
            },
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
  readonly addChecklistItem = this.effect(
    (
      checklistItemInfo$: Observable<{
        name: string;
        checklistId: string;
        checked: boolean;
      }>
    ) => {
      return checklistItemInfo$.pipe(
        switchMap(({ name, checklistId, checked }) =>
          this._cardService
            .createChecklistItem(name, checked, checklistId)
            .pipe(
              tap({
                next: response => {
                  const checklistItem = response.createdChildObject;
                  // Add new checklist item to state
                  this.addNewChecklistItem({ checklistItem, checklistId });
                },
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  readonly addCardSubTask = this.effect((subTaskName$: Observable<string>) => {
    return subTaskName$.pipe(
      withLatestFrom(this.select(state => state.card?.id)),
      switchMap(([subTaskName, cardId]: [string, string]) =>
        this._cardService.createCardSubTask(cardId, subTaskName).pipe(
          tap({
            next: response => {
              const subtask = response.createdChildObject;
              // Add new sub task to the state
              this.addNewSubTask(subtask);
            },
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
  // create comment
  readonly addCardComment = this.effect(
    (
      commentInfo$: Observable<{
        parentRoleType: string;
        parentId: string;
        name: string;
        time: string;
        description: string;
        relation: string;
      }>
    ) => {
      return commentInfo$.pipe(
        withLatestFrom(
          // Logger user
          this._store.select(AuthStoreSelectors.selectLoggedUser)
        ),
        switchMap(([commentInfo, loggedUser]) =>
          this._cardService
            .createComment(
              commentInfo.name,
              commentInfo.time,
              commentInfo.parentRoleType,
              commentInfo.parentId,
              commentInfo.relation
            )
            .pipe(
              tap({
                next: response => {
                  const comment = response.createdChildObject;
                  // Add new comment to state
                  this.addNewComment({
                    comment,
                    parentRoleType: commentInfo.parentRoleType,
                    parentId: commentInfo.parentId,
                    users: loggedUser ? [loggedUser] : [],
                  });
                },
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );
  // update comment
  readonly updateComment = this.effect(
    (
      commentInfo$: Observable<{
        commentId: string;
        content: string;
        description: string;
        lastUpdated: string;
        parentRoleType: string;
      }>
    ) => {
      return commentInfo$.pipe(
        withLatestFrom(
          // Logger user
          this._store.select(AuthStoreSelectors.selectLoggedUser)
        ),
        switchMap(([commentInfo]) =>
          this._cardService
            .updateComment(
              commentInfo.content,
              commentInfo.lastUpdated,
              commentInfo.commentId
            )
            .pipe(
              tap(updatedComment => {
                this.setUpdatedComment({
                  updatedComment: updatedComment,
                  parentRolType: commentInfo.parentRoleType,
                });
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  //**************************************************Updaters***************************************

  // ---------------------set the coming card---------------------
  readonly setCard = this.updater(
    (
      state,
      payload: {
        card: CardModelResponse;
        isParent: boolean;
        parentName?: string;
        parentId?: string;
      }
    ) => {
      const updatedCard = { ...payload.card };
      return {
        ...state,
        card: updatedCard,
        isParent: payload.isParent,
        parentName: payload.parentName,
        parentId: payload.parentId,
      };
    }
  );

  readonly setUpdatedCard = this.updater((state, updatedCard: any) => ({
    ...state,
    card: {
      ...state.card,
      ...updatedCard,
    },
  }));
  readonly setUpdatedTag = this.updater(
    (state, updatedTag: CreatedChildObject) => {
      if (!state.card) return state;

      const tag = state.card.tag.map(tag => {
        if (tag.id === updatedTag.id) {
          return {
            ...tag,
            name: updatedTag.name,
            color: updatedTag.value ? updatedTag.value.color : tag.color,
          };
        }
        return tag;
      });

      return {
        ...state,
        card: {
          ...state.card,
          tag: tag,
        },
      };
    }
  );

  readonly setCardNewResponsible = this.updater(
    (state, payload: { responsible: UserModel }) => {
      if (state.card.responsible[0].id === payload.responsible.id) {
        return {
          ...state,
          card: {
            ...state.card,
            responsible: [],
          },
        };
      } else {
        return {
          ...state,
          card: {
            ...state.card,
            responsible: [payload.responsible],
          },
        };
      }
    }
  );

  readonly setCardNewTag = this.updater((state, tag: Tag) => ({
    ...state,
    card: {
      ...state.card,
      tag: [...state.card.tag, tag],
    },
  }));
  readonly setCardNewAttachement = this.updater(
    (state, newDocument: { id: string; name: string; value: any }) => ({
      ...state,
      card: {
        ...state.card,
        document: [...state.card.document, newDocument],
      },
    })
  );

  // ---------------------set the new  checklist created ---------------------
  readonly addNewChecklist = this.updater(
    (state, checklist: CreatedChildObject) => ({
      ...state,
      card: {
        ...state.card,
        checklist: [
          ...(state.card?.checklist || []),
          {
            id: checklist.id,
            name: checklist.name,
            description: checklist.description,
          },
        ],
      },
    })
  );

  readonly removeChecklist = this.updater((state, id: string) => ({
    ...state,
    card: {
      ...state.card,
      checklist: state.card?.checklist?.filter(
        checklist => checklist.id !== id
      ),
    },
  }));

  readonly removeAttachment = this.updater((state, id: string) => ({
    ...state,
    card: {
      ...state.card,
      document: state.card?.document?.filter(document => document.id !== id),
    },
  }));

  // ---------------------set the new  checklistItem created ---------------------
  readonly addNewChecklistItem = this.updater(
    (
      state,
      payload: { checklistItem: CreatedChildObject; checklistId: string }
    ) => {
      const updatedChecklist = (state.card?.checklist || []).map(checklist => {
        if (checklist.id === payload.checklistId) {
          return {
            ...checklist,
            checkListItem: [
              ...(checklist.checkListItem || []),
              {
                id: payload.checklistItem.id,
                name: payload.checklistItem.name,
                description: payload.checklistItem.description,
                checked: payload.checklistItem.value.checked,
              },
            ],
          };
        }
        return checklist;
      });

      return {
        ...state,
        card: {
          ...state.card,
          checklist: updatedChecklist,
        },
      };
    }
  );

  readonly addNewSubTask = this.updater(
    (state, createdSubTask: CreatedChildObject) => {
      const newSubCard: SubCardModelResponse = {
        id: createdSubTask.id,
        name: createdSubTask.name,
        startDate: createdSubTask.startDate,
        endDate: createdSubTask.endDate,
        priority: createdSubTask.priority || '',
        isCompleted: createdSubTask.value.isCompleted,
        responsible: createdSubTask.value.responsible || [],
        comment: 0,
        document: 0,
      };
      return {
        ...state,
        card: {
          ...(state.card || {}),
          card: [...(state.card?.card || []), newSubCard],
        },
      };
    }
  );

  //---------------------set the updated  comment  ---------------------
  readonly setUpdatedComment = this.updater(
    (
      state,
      data: { updatedComment: CreatedChildObject; parentRolType: string }
    ) => {
      const newUpdatedComment: any = {
        id: data.updatedComment.id,
        name: data.updatedComment.name || '',
        description: data.updatedComment.description || '',
        lastUpdated: data.updatedComment.value.lastUpdated,
      };

      if (data.parentRolType === 'card') {
        const updatedComments = (state.card?.comment || []).map(comment => {
          if (comment.id === newUpdatedComment.id) {
            return {
              ...comment,
              name: newUpdatedComment.name,
              description: newUpdatedComment.description,
              lastUpdated: newUpdatedComment.lastUpdated as string,
            };
          }
          return comment;
        });

        return {
          ...state,
          card: {
            ...state.card,
            comment: updatedComments,
          },
        };
      } else {
        const updatedComments = (state.card?.comment || []).map(
          parentComment => {
            if (parentComment.comment) {
              const updatedChildComments = (parentComment.comment || []).map(
                childComment => {
                  if (childComment.id === newUpdatedComment.id) {
                    return {
                      ...childComment,
                      name: newUpdatedComment.name,
                      description: newUpdatedComment.description,
                      lastUpdated: newUpdatedComment.lastUpdated as string,
                    };
                  }
                  return childComment;
                }
              );

              return {
                ...parentComment,
                comment: updatedChildComments,
              };
            }
            return parentComment;
          }
        );

        return {
          ...state,
          card: {
            ...state.card,
            comment: updatedComments,
          },
        };
      }
    }
  );

  //---------------------set the new  comment created ---------------------
  readonly addNewComment = this.updater(
    (
      state,
      payload: {
        comment: CreatedChildObject;
        parentRoleType: string;
        parentId: string;
        users: AuthUser[];
      }
    ) => {
      const updatedState = { ...state };

      if (payload.parentRoleType === 'Card') {
        // Update comment inside the card
        updatedState.card = {
          ...updatedState.card,
          comment: [
            ...(updatedState.card?.comment || []),
            {
              id: payload.comment.id,
              name: payload.comment.name,
              time: payload.comment.value.time,
              users: payload.users as AuthUser[],
              lastUpdated: payload.comment.value.lastUpdated || '',
              comment: [],
            },
          ],
        };
      } else if (payload.parentRoleType === 'Comment') {
        // Update comment inside the comments list
        updatedState.card = {
          ...updatedState.card,
          comment: (updatedState.card?.comment || []).map(comment => {
            if (comment.id === payload.parentId) {
              return {
                ...comment,
                comment: [
                  ...(comment.comment || []),
                  {
                    id: payload.comment.id,
                    name: payload.comment.name,
                    description: payload.comment.description,
                    time: payload.comment.value.time,
                    lastUpdated: '',
                    users: payload.users as AuthUser[],
                  },
                ],
              };
            }
            return comment;
          }),
        };
      }

      return updatedState;
    }
  );

  readonly deleteComment = this.updater((state, id: string) => ({
    ...state,
    card: {
      ...state.card,
      comment: state.card?.comment?.filter(comment => comment.id !== id),
    },
  }));

  // Store method to delete a nested comment
  readonly deleteSubComment = this.updater((state, id: any) => {
    const updatedComments =
      state.card?.comment?.map(comment => ({
        ...comment,
        comment: comment.comment.filter(
          nestedComment => nestedComment.id !== id
        ),
      })) || [];

    return {
      ...state,
      card: {
        ...state.card,
        comment: updatedComments,
      },
    };
  });

  // Effect to handle checklist title update
  readonly editChecklistTitle = this.effect(
    (checklistInfo$: Observable<{ checklistId: string; newTitle: string }>) => {
      return checklistInfo$.pipe(
        switchMap(({ checklistId, newTitle }) =>
          this._objectService
            .updateObject('Checklist', checklistId, { name: newTitle })
            .pipe(
              tap({
                next: () => {
                  this.updateChecklistTitle({ checklistId, newTitle });
                },
              }),
              catchError(() => EMPTY)
            )
        )
      );
    }
  );

  // Updater to update the checklist title in the state
  readonly updateChecklistTitle = this.updater(
    (
      state,
      { checklistId, newTitle }: { checklistId: string; newTitle: string }
    ) => ({
      ...state,
      card: {
        ...state.card,
        checklist: state.card?.checklist?.map(checklist =>
          checklist.id === checklistId
            ? { ...checklist, name: newTitle }
            : checklist
        ),
      },
    })
  );
}
