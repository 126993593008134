import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject, takeUntil } from 'rxjs';

import { AuthService } from '@excelway/services/auth.service';
import { AutorizationService } from '@excelway/services/autorization.service';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Field } from 'app/modules/user-interface/user-interface.store';
import { TranslationService } from '@excelway/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'board-settings',
  templateUrl: './board-settings.component.html',
})
export class BoardSettingsComponent implements OnInit {
  interfaceId = 'cm24suqiz0006uvcoy6jch464';
  fields: Field[] = [];
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  isTemplate: boolean;
  boardPanels = [
    {
      id: 'object-details',
      icon: 'heroicons_outline:information-circle',
      title: 'BOARD_DETAILS_TAB',
      description: 'BOARD_DETAILS_TAB_DESCRIPTION',
    },
    {
      id: 'access-invites',
      icon: 'heroicons_outline:user-group',
      title: 'BOARD_ACCESS_&_INVITE_TAB_TITLE',
      description: 'BOARD_ACCESS_&_INVITE_TAB_DESCRIPTION',
    },
    // {
    //   id: 'choose-notifications',
    //   icon: 'heroicons_outline:bell',
    //   title: 'Notifications',
    //   description: 'Choose your notification preferences',
    // },
    // {
    //   id: 'customize-cards-fields',
    //   icon: 'heroicons_outline:bell',
    //   title: 'Card fields',
    //   description: 'customise cards display fields',
    // },
  ];

  templatePanels = [
    {
      id: 'object-details',
      icon: 'heroicons_outline:information-circle',
      title: 'TEMPLATE_BOARD_SETTINGS_DETAILS_HEADER_TAB',
      description: 'TEMPLATE_BOARD_SETTINGS_DETAILS_TEXT_TAB',
    },
    {
      id: 'availability',
      icon: 'heroicons_outline:squares-plus',
      title: 'TEMPLATE_BOARD_SETTINGS_AVAILABILITY_HEADER_TAB',
      description: 'TEMPLATE_BOARD_SETTINGS_AVAILABILITY_TEXT_TAB',
    },
    {
      id: 'access-template',
      icon: 'heroicons_outline:user-group',
      title: 'TEMPLATE_BOARD_SETTINGS_ACCESS_HEADER_TAB',
      description: 'TEMPLATE_BOARD_SETTINGS_ACCESS_TEXT_TAB',
    },
    {
      id: 'tags',
      icon: 'heroicons_outline:tag',
      title: 'TEMPLATE_BOARD_SETTINGS_TAGS_HEADER_TAB',
      description: 'TEMPLATE_BOARD_SETTINGS_TAGS_TEXT_TAB',
    },
    {
      id: 'custom-fields',
      icon: 'heroicons_outline:queue-list',
      title: 'TEMPLATE_BOARD_SETTINGS_DISPLAY_HEADER_TAB',
      description: 'TEMPLATE_BOARD_SETTINGS_DISPLAY_TEXT_TAB',
    },
  ];
  selectedPanel: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  role: string;

  /**
   * Constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    private _changeDetectorRef: ChangeDetectorRef,
    public _matDialogRef: MatDialogRef<BoardSettingsComponent>,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private authService: AuthService,
    private _permissionService: AutorizationService,
    private _translationService: TranslationService,
    private translate: TranslateService
  ) {
    if (data.tabToOpen) {
      this.selectedPanel = this.data.tabToOpen;
    } else {
      this.selectedPanel = 'object-details';
    }
  }

  ngOnInit(): void {
    this._translationService
      .loadComponentTranslations(this.interfaceId)
      .subscribe(fields => {
        this.fields = fields;
      });
    this.isTemplate = this.data.value?.isTemplate;
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Set the drawerMode and drawerOpened
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        } else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
    this.getUserRole();
  }

  getUserRole = (): void => {
    this.authService.getAuthUser().then(identity => {
      if (identity) {
        this._permissionService
          .getAccessLevel(this.data.id, identity.id)
          .subscribe(response => {
            this.role = response.role;
          });
      }
    });
  };

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Navigate to the panel
   *
   * @param panel
   */
  goToPanel(panel: string): void {
    this.selectedPanel = panel;

    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }
  }

  /**
   * Get the details of the panel
   *
   * @param id
   */
  getPanelInfo(id: string): any {
    if (!this.isTemplate) {
      return this.boardPanels.find(panel => panel.id === id);
    } else {
      return this.templatePanels.find(panel => panel.id === id);
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
