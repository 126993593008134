<div class="h-full">
  <form [formGroup]="profileDetailsForm" action="">
    <div class="grid grid-cols-4 gap-8">
      <div class="col-span-4 flex flex-col gap-y-1 border-b pb-5">
        <div
          class="w-[136px] text-gray-900 text-base font-medium leading-normal settings-section-title">
          Summaries
        </div>
      </div>

      <div class="col-span-4">
        <div
          class="block text-gray-700 text-sm font-bold mb-2 settings-section-title">
          Send me daily summaries
        </div>
      </div>

      <!-- //////////////////////////////////////// -->
      <div class="col-span-4 border-b-2 pb-5">
        <div class="w-1/2">
          <label
            id="listbox-label"
            class="block text-gray-700 text-sm font-bold mb-2 label"
            >Select hours</label
          >
          <div class="relative mt-2">
            <button
              (click)="OpenDropdownDailyummaries()"
              type="button"
              class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label">
              <span class="block truncate">{{
                profileDetailsForm.get('selecthoursdailyummaries')?.value ||
                  'Select hour'
              }}</span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <svg
                  class="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>
            <ul
              *ngIf="isOpenDailySummaries"
              class="absolute z-10 mt-1 w-full h-55 max-h-44 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm bottom-12">
              <li
                *ngFor="let item of hours_of_day"
                class="text-gray-900 relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 rounded-md"
                id="listbox-option-0"
                role="option"
                (click)="onDropdownItemClick1($event, item)">
                <span class="font-normal block truncate">{{ item }}</span>
                <span
                  *ngIf="selecthoursWeeklyummaries === item"
                  class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <svg
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-span-4">
        <div
          class="block text-gray-700 text-sm font-bold mb-2 settings-section-title">
          Send me weekly summaries on
        </div>
      </div>

      <!-- //////////////////////////////////////// -->
      <div class="col-span-2">
        <label
          class="block text-gray-700 text-sm font-bold mb-2 label"
          for="name">
          Select day
        </label>
        <mat-select
          [formControl]="language"
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
          <mat-select-trigger>
            {{ language.value || '' }}
          </mat-select-trigger>
          <mat-option *ngFor="let language of daysList" [value]="language">{{
            language
          }}</mat-option>
        </mat-select>
      </div>

      <!-- //////////////////////////////////////// -->
      <div class="col-span-2">
        <div>
          <label
            id="listbox-label"
            class="block text-gray-700 text-sm font-bold mb-2 label"
            >Select hours</label
          >
          <div class="relative mt-2">
            <button
              (click)="OpenDropdownWeeklySummaries()"
              type="button"
              class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label">
              <span class="block truncate">{{
                profileDetailsForm.get('selecthoursWeeklyummaries')?.value ||
                  'Select hour'
              }}</span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <svg
                  class="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true">
                  <path
                    fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>
            <ul
              *ngIf="isOpenWeeklySummaries"
              class="absolute z-10 mt-1 w-full h-55 max-h-44 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm bottom-12">
              <li
                *ngFor="let item of hours_of_day"
                class="text-gray-900 relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-100 rounded-md"
                id="listbox-option-0"
                role="option"
                (click)="onDropdownItemClick2($event, item)">
                <span class="font-normal block truncate">{{ item }}</span>
                <span
                  *ngIf="selecthoursWeeklyummaries === item"
                  class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <svg
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true">
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
