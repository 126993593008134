import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { provideComponentStore } from '@ngrx/component-store';
import { EMPTY, Subject, takeUntil, tap } from 'rxjs';

import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

import { Router } from '@angular/router';
import { AuthService } from '@excelway/services/auth.service';
import { AutorizationService } from '@excelway/services/autorization.service';
import { TemplateService } from '@excelway/services/template.service';
import { TranslationService } from '@excelway/services/translation.service';
import { AuthUser } from '@excelway/types/auth-user.types';
import { Store } from '@ngrx/store';
import { ObjectSettingsComponent } from 'app/common-dialogs/settings/settings.component';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { BoardSettingsComponent } from 'app/modules/boards/board-settings/board-settings.component';
import { Field } from 'app/modules/user-interface/user-interface.store';
import { AcivitySettingsDialogComponent } from 'app/modules/workshops/scrumboard/board/activity-settings/activity-settings-dialog';
import { StartBrainstormDialogComponent } from 'app/modules/workshops/start-brainstorm-dialog/start-brainstorm-dialog';
import { StartVotesDialogComponent } from 'app/modules/workshops/start-votes-dialog/start-votes-dialog';
import { WorkshopService } from 'app/modules/workshops/workshop.activities';
import { AuthStoreSelectors } from 'app/store/auth';
import { ModernStore } from './modern.store';

@Component({
  selector: 'modern-layout',
  templateUrl: './modern.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideComponentStore(ModernStore)],
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  fields: Field[] = [];
  header$ = this._store.select(state => state.header);
  header: any;
  sessionUserRole: string;

  isScreenSmall: boolean;
  navigation: Navigation;
  workFlowStep = 'presentation';
  workshopBackUrl: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  user: any;
  isAccountVerified: boolean;
  role: string;

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _workshopService: WorkshopService,
    private _templateService: TemplateService,
    private readonly _store: ModernStore,
    private readonly _userStore: Store,
    private readonly _router: Router,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private authService: AuthService,
    private _permissionService: AutorizationService,
    private _translationService: TranslationService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.showVerificationBanner();
    this._userStore
      .select(AuthStoreSelectors.selectLoggedUser)
      .pipe()
      .subscribe((user: AuthUser) => {
        this.user = user;
      });

    // implementing translation
    this._translationService.loadTranslationsSubscriptionVersion(
      'cm2c8z48m00mwg0mzllpge1t7'
    );

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    this.sessionUserRole = 'animator';

    this.header$.subscribe(header => {
      this.header = header; // Store the value of header in headerValue
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
    this.getUserRole();
  }
  calculateTotalCost(): number {
    let totalCost = 0;
    this.header.section.forEach(section => {
      section.card.forEach(card => {
        if (this.header.enableCardCost && card.cost !== undefined) {
          totalCost += card.cost;
        }
      });
    });

    return totalCost;
  }

  getUserRole = (): void => {
    const workspaceId = localStorage.getItem('activeWorkspaceId');
    if (workspaceId) {
      this.authService.getAuthUser().then(identity => {
        if (identity) {
          this._permissionService
            .getAccessLevel(workspaceId, identity.id)
            .subscribe(response => {
              this.role = response.role;
            });
        }
      });
    }
  };

  calculateTotalDuration(): number {
    let totalDuration = 0;
    this.header.section.forEach(section => {
      section.card.forEach(card => {
        if (this.header.enableCardDuration && card.duration !== undefined) {
          totalDuration += card.duration;
        }
      });
    });

    return totalDuration;
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * navigate back to the previous URL
   *
   */
  goBack(): void {
    this._store.goBack();
  }

  showVerificationBanner(): void {
    const localStorageValue = localStorage.getItem('isAccountVerified');
    if (localStorageValue !== null) {
      this.isAccountVerified = JSON.parse(localStorageValue);
    }
  }

  openBoardSettings(): void {
    this.header.tabToOpen = 'object-details';
    const dialogRef = this.dialog.open(BoardSettingsComponent, {
      data: this.header,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '88%',
      width: '88%',
    });
    // TODO Erase after CNDP and use state instead
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (typeof result === 'string') {
          // Handle the case where result is boardName
          this.header$
            .pipe(
              tap(header => {
                if (header) {
                  header.name = result;
                  this.cdr.detectChanges();
                }
              })
            )
            .subscribe();
        } else {
          // Handle the case where result is payload
          this.saveBoardAsTemplate(result.projectId, result.boardId);
        }
      }
    });
  }

  saveBoardAsTemplate(projectId: string, boardId: string): void {
    this._templateService
      .toggleBoardTemplateCreation(projectId, boardId)
      .subscribe(
        response => {
          const newObjectId = response.newObject?.id;

          if (newObjectId) {
            localStorage.setItem('originalBoardId', boardId);

            // Navigate to the new board template
            this._router.navigate([
              '/projects/project',
              projectId,
              'boards',
              newObjectId,
              'kanban',
            ]);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error => {
          return EMPTY;
        }
      );
  }

  openBoardInviteSettings(): void {
    this.header.tabToOpen = 'access-invites';
    this.dialog.open(BoardSettingsComponent, {
      data: this.header,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '88%',
      width: '88%',
    });
  }

  updateWorkflow(workFlowStep: string): any {
    const activityData = {
      workFlowStep: workFlowStep,
      isPublic: true,
      value: { priority: 'low' },
    };
    this._workshopService
      .updateActivity(activityData, this.header.id)
      .subscribe();
  }

  showStartBrainstormDialog(): any {
    this.dialog.open(StartBrainstormDialogComponent, {
      data: {
        boardId: this.header.id,
      },
    });
  }

  showStartVotesDialog(): any {
    this.dialog.open(StartVotesDialogComponent, {
      data: {
        boardId: this.header.id,
      },
    });
  }

  startVoting(): void {
    const activityData = {
      workFlowStep: 'vote',
      value: { priority: 'low' },
    };
    this._workshopService
      .updateActivity(activityData, this.header.id)
      .subscribe();
  }

  openWorkshopSettings(): void {
    this.header.tabToOpen = 'object-details';
    const dialogRef = this.dialog.open(ObjectSettingsComponent, {
      data: this.header,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '88%',
      width: '88%',
    });
    dialogRef.afterClosed().subscribe(workshopName => {
      if (workshopName) {
        this.header$
          .pipe(
            tap(header => {
              if (header) {
                header.name = workshopName;
              }
            })
          )
          .subscribe();
      }
      this.cdr.detectChanges();
    });
  }

  openWorkshopInviteSettings(): void {
    this.header.tabToOpen = 'access-invites';
    this.dialog.open(ObjectSettingsComponent, {
      data: this.header,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '88%',
      width: '88%',
    });
  }

  openActivitySettings(): void {
    this.dialog.open(AcivitySettingsDialogComponent, {
      data: this.header.id,
    });
  }
}
