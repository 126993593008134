import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '@excelway/services/translation.service';
import { Store } from '@ngrx/store';
import { Field } from 'app/modules/user-interface/user-interface.store';
import { WorkshopsStoreActions } from 'app/store/workshops';
import { Subscription } from 'rxjs';
import { WorkshopService } from '../../../workshop.activities';

@Component({
  selector: 'activity-settings-dialog',
  templateUrl: './activity-settings-dialog.html',
  providers: [DatePipe],
})
export class AcivitySettingsDialogComponent implements OnInit, OnDestroy {
  fields: Field[] = [];
  private subscription: Subscription;
  board: boolean;
  workshopId = '';
  isPublic = true;
  composeForm: UntypedFormGroup;
  selectedColor = [];
  selectedColorString: any;
  checkedToggle = false;
  showMembers = false;
  hideAllCards = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    public _formbuilder: FormBuilder,
    private datePipe: DatePipe,
    private _Activatedroute: ActivatedRoute,
    private _workshopService: WorkshopService,
    private _formBuilder: UntypedFormBuilder,
    private readonly _store: Store,
    public matDialogRef: MatDialogRef<AcivitySettingsDialogComponent>,
    private _translationService: TranslationService
  ) {}
  ngOnInit(): void {
    this._translationService.loadTranslationsSubscriptionVersion(
      'cm2egqs3w000ng0jaw66o7vb1'
    );
    // Create the form
    this.composeForm = this._formBuilder.group({
      checkboxAllColors: [''],
      cc: [''],
      bcc: [''],
      subject: [''],
      body: [''],
    });
    console.log(this.data);
    this.isPublic = this.data.value.isPublic;
  }

  colorChanged(event: any): void {
    this.selectedColor = event;
    this.selectedColorString = this.selectedColor.join('');
  }

  startBrainstorm(): void {
    const activityData = {
      workFlowStep: 'brainstorm',
      brainstormColors: this.selectedColorString,
      isPublic: true,
    };
    this._workshopService
      .updateActivity(activityData, this.data.boardId)
      .subscribe();
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkToggled(): void {
    const activityData = {
      showLabels: true,
    };
    this._workshopService.updateActivity(activityData, this.data).subscribe();
    this._store.dispatch(
      WorkshopsStoreActions.updateDisplayLabel({
        label: this.checkedToggle,
      })
    );
  }

  showMembersNames(): void {
    const activityData = {
      showLabels: true,
    };
    this._workshopService.updateActivity(activityData, this.data).subscribe();
    this._store.dispatch(
      WorkshopsStoreActions.toggleShowMembersName({
        enabled: this.showMembers,
      })
    );
    // this._store.dispatch(
    //     WorkshopBrainstormingStoreActions.toggleShowMembersName({
    //         enabled: this.showMembers,
    //     })
    // );
  }

  checkShowAllCards(): void {
    const activityData = {
      showLabels: true,
    };
    console.log(this.checkedToggle);
    this._workshopService.updateActivity(activityData, this.data).subscribe();
    this._store.dispatch(
      WorkshopsStoreActions.toggleShowAllCards({
        enabled: this.hideAllCards,
      })
    );
    // this._store.dispatch(
    //     WorkshopBrainstormingStoreActions.toggleShowAllCards({
    //         enabled: this.hideAllCards,
    //     })
    // );
  }
}
